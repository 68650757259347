import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import myAccountReducer from "./reducers/my-account";
import paymentOptionReducer from "./reducers/payment-option";
import clientReducer from "./reducers/client";
import roomReducer from "./reducers/room";
import roomClientReducer from "./reducers/room-client";
import clientRoomReducer from "./reducers/client-room";
import dailyReportReducer from "./reducers/daily-report";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        myAccount: myAccountReducer,
        paymentOption: paymentOptionReducer,
        client: clientReducer,
        room: roomReducer,
        roomClient: roomClientReducer,
        clientRoom: clientRoomReducer,
        dailyReport: dailyReportReducer
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch