import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, Chip, Select, MenuItem, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { getPayments, pay } from '../../../../store/reducers/client-room';
import toMoney from '../../../../utils/to-money';
import dayjs from 'dayjs';
import { IClientRoomPay } from '../../../../types/client-room/pay';
import { emptyAutocompleteList, getAutocompleteList, getList } from '../../../../store/reducers/payment-option';
import { IAutocomplete } from '../../../../types/autocomplete';
import { showWarningToast } from '../../../../toast/warn';
import getRole from '../../../../utils/get-role';
import toFixed from '../../../../utils/to-fixed';
import isWholeNumber from '../../../../utils/is-whole-number';

interface IProps {
    client_room_id: number,
    is_paid: boolean
}

const PaymentClientRoomPayments: React.FunctionComponent<IProps> = ({ is_paid, client_room_id }) => {

    // Global
    const navigate = useNavigate();
    const clientRoom = useSelector((state: RootState) => state.clientRoom);
    const paymentOption = useSelector((state: RootState) => state.paymentOption);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getPayments({
            page: value,
            client_room_id
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN')) {
            dispatchAction(getPayments({
                page: 1,
                client_room_id
            }));

            dispatchAction(getList({
                page: 1,
                search: ''
            }))
        }

    }, []);

    useEffect(() => {
        if (clientRoom.isRefetchPaymentList)
            dispatchAction(getPayments({
                page: page,
                client_room_id
            }));

    }, [clientRoom.isRefetchPaymentList]);

    // Create payment
    const [isCreatePaymentModalOpen, setIsCreatePaymentModalOpen] = useState(false);
    const createPaymentModalHandleOpen = () => setIsCreatePaymentModalOpen(true);
    const createPaymentModalHandleClose = () => setIsCreatePaymentModalOpen(false);
    const payDataPlaceholder = {
        client_room_id: client_room_id,
        payment_note: '',
        amount: 0,
        cash: 0,
        payment_type_id: 0,
        months: 0
    }
    const [payData, setPayData] = useState<IClientRoomPay>(payDataPlaceholder);

    // Payment option autocomplete
    const [payPaymentOptionAutocompleteSelected, setPayPaymentOptionAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [payPaymentOptionAutocompleteSearchId, setCreateClientAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [payPaymentOptionAutocompleteInput, setCreateClientAutocompleteInput] = useState('');
    const [payPaymentOptionAutocompletePage, setCreateClientAutocompletePage] = useState(1);

    const payPaymentOptionAutocompleteNextPage = () => {
        if (payPaymentOptionAutocompletePage + 1 <= paymentOption.autocompleteListPagination.totalPages) {
            dispatchAction(getAutocompleteList({
                page: payPaymentOptionAutocompletePage + 1,
                search: payPaymentOptionAutocompleteInput,
                addToCurrent: true,
            }));

            setCreateClientAutocompletePage(previousVal => previousVal + 1);
        }
    }

    const onPayPaymentOptionAutocompleteInputChange = (search: string) => {

        setPayPaymentOptionAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setCreateClientAutocompletePage(1);
            dispatchAction(emptyAutocompleteList());
            return;
        }

        const searchingFound = paymentOption.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(payPaymentOptionAutocompleteSearchId);

        setCreateClientAutocompleteSearchId(setTimeout(() => payPaymentOptionAutocompleteSearch(search), 300))
    }

    const payPaymentOptionAutocompleteSearch = (search: string) => {
        setCreateClientAutocompletePage(1);
        dispatchAction(getAutocompleteList({
            page: 1,
            search: search
        }));
    }

    const payClickEvent = () => {
        if (
            !(payPaymentOptionAutocompleteSelected.value && payPaymentOptionAutocompleteSelected.value > 0)
        ) {
            showWarningToast('Search and select a payment method');
            return;
        }

        if (payData.cash < payData.amount) {
            showWarningToast('Cash is lesser than the amount');
            return;
        }

        if (payData.amount > Number(toFixed(clientRoom.singleData.balance, 2))) {
            showWarningToast(`Amount must not exceed the balance`);
            return;
        }

        dispatchAction(pay({
            client_room_id,
            payment_type_id: payPaymentOptionAutocompleteSelected.value,
            amount: payData.amount,
            cash: payData.cash,
            payment_note: payData.payment_note
        }));
    }

    useEffect(() => {

        if (clientRoom.isPaySuccess)
            setIsCreatePaymentModalOpen(false);

    }, [clientRoom.isPaySuccess])

    return (
        <>
            {!is_paid ?
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={createPaymentModalHandleOpen}
                >
                    Create Payment
                </Button>
                : null}
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="client-room-payments-table"
            >
                {clientRoom.isPaymentListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Amount</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Cash</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Type</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Payment Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clientRoom.paymentList && clientRoom.paymentList.length > 0
                                ?
                                clientRoom.paymentList.map(item => {
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.amount)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.cash)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.payment_type}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{dayjs(item.payment_date).format('MMMM D, YYYY hh:mm A')}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Stack direction="row" spacing={2}>
                                                    <Button onClick={() => { localStorage.setItem('print', JSON.stringify(item)); window.open(`/receipt/`, '_blank');}} variant="contained" color="warning" startIcon={<PrintIcon />}>
                                                        Print Receipt
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            {!clientRoom.isPaymentListLoading && clientRoom.paymentListPagination && clientRoom.paymentListPagination.total > 0 ?
                <Box
                    className="client-room-payments-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                            marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {clientRoom.paymentListPagination.fromData} - {clientRoom.paymentListPagination.toData} out of {clientRoom.paymentListPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={clientRoom.paymentListPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}
            <Modal
                open={isCreatePaymentModalOpen}
                className="payment-options-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="payment-options-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Create Payment
                    </Typography>

                    <Divider />

                    <Box
                        className="clients-modal-form-controls"
                    >
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Amount"
                            variant="outlined"
                            fullWidth
                            required
                            value={payData.amount}
                            onChange={(e) => {
                                setPayData((previousVal: IClientRoomPay) => {
                                    return { ...previousVal, amount: Number(e.target.value) };
                                })
                            }}
                            disabled={clientRoom.isPayLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            type="number"
                            label="Cash"
                            variant="outlined"
                            fullWidth
                            required
                            value={payData.cash}
                            onChange={(e) => {
                                setPayData((previousVal: IClientRoomPay) => {
                                    return { ...previousVal, cash: Number(e.target.value) };
                                })
                            }}
                            disabled={clientRoom.isPayLoading}
                        />
                        <Box
                            className="payment-change-container"
                        >
                            <Typography variant="h6">
                                Change
                            </Typography>
                            <Typography>
                                {toMoney(payData.cash - payData.amount)}
                            </Typography>
                        </Box>
                        <Autocomplete
                            noOptionsText="No result"
                            disablePortal
                            ListboxProps={{
                                role: 'list-box',
                                onScroll: (event: React.SyntheticEvent) => {
                                    const listboxNode = event.currentTarget;
                                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                        payPaymentOptionAutocompleteNextPage();
                                    }
                                }
                            }}
                            loading={paymentOption.isAutocompleteListLoading}
                            options={paymentOption.autocompleteList}
                            value={payPaymentOptionAutocompleteSelected}
                            onChange={(event: any, newValue: IAutocomplete | null) => {
                                if (newValue)
                                    setPayPaymentOptionAutocompleteSelected(newValue)
                            }}
                            inputValue={payPaymentOptionAutocompleteInput}
                            onInputChange={(event, newInputValue) => {
                                setCreateClientAutocompleteInput(newInputValue);
                                onPayPaymentOptionAutocompleteInputChange(newInputValue);
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} value={option.value} key={option.value}>
                                        {option.label}
                                    </li>
                                );
                            }}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            renderInput={(params) => <TextField required {...params} label="Search Payment Option" />}
                            disabled={paymentOption.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            label="Note"
                            variant="outlined"
                            fullWidth
                            multiline
                            maxRows={5}
                            minRows={5}
                            value={payData.payment_note}
                            onChange={(e) => {
                                setPayData((previousVal: IClientRoomPay) => {
                                    return { ...previousVal, payment_note: e.target.value };
                                })
                            }}
                            disabled={clientRoom.isPayLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={payClickEvent}
                            disabled={clientRoom.isPayLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: clientRoom.isPayLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Pay
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={createPaymentModalHandleClose}
                            disabled={clientRoom.isPayLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default PaymentClientRoomPayments;