import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IClientListItem } from '../../../../types/client/list-item';
import { IClientCreate } from '../../../../types/client/create';
import { IClientEdit } from '../../../../types/client/edit';
import { showWarningToast } from '../../../../toast/warn';
import { AppDispatch, RootState } from '../../../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { getList, create, update, getSingleForEdit, _delete } from '../../../../store/reducers/client';
import { IAutocomplete } from '../../../../types/autocomplete';
import getRole from '../../../../utils/get-role';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getListBooked } from '../../../../store/reducers/client-room';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const localizer = momentLocalizer(moment)

interface ICalendarEvent {
    title: string,
    start: Date,
    end: Date,
    allDay: boolean,
    client_room_id: number,
    client_id: number
}

interface IEventProps {
    event: ICalendarEvent
}

const Event: React.FunctionComponent<IEventProps> = ({ event }) => (
    <>
        <div
            onClick={()=>{
                window.open(`/payments/${event.client_id}/room/${event.client_room_id}`, "_blank");
            }}
        >
            <Typography
                sx={{
                    fontSize: '11px'
                }}
            >
                {event.title}
            </Typography>
        </div>
    </>
);

const CalendarComponent: React.FunctionComponent = () => {

    const clientRoom = useSelector((state: RootState) => state.clientRoom);
    const myAccount = useSelector((state: RootState) => state.myAccount);
    const dispatchAction = useDispatch<AppDispatch>();
    const [event, setEvent] = useState<ICalendarEvent[]>([]);
    const [currentYear, setCurrentYear] = useState(0);
    const [currentMonth, setCurrentMonth] = useState(0);
    const [calendarDate, setCalendarDate] = useState<Dayjs | null>(null);

    useEffect(() => {
        const date = new Date();

        dispatchAction(getListBooked({
            year: date.getFullYear().toString(),
            month: (date.getMonth() + 1).toString()
        }))
    }, [])

    useEffect(() => {

        const convertedToEvent: ICalendarEvent[] = [];

        clientRoom.listBooked.forEach(item => {
            convertedToEvent.push({
                allDay: true,
                title: `${item.name} - ${item.first_name} ${item.middle_name} ${item.last_name}`,
                start: new Date(item.date),
                end: new Date(item.date_end),
                client_id: item.client_id,
                client_room_id: item.client_room_id
            })
        })

        setEvent(convertedToEvent);

    }, [clientRoom.listBooked])

    useEffect(() => {

        if (currentMonth == 0 || currentYear == 0)
            return;

        dispatchAction(getListBooked({
            year: currentYear.toString(),
            month: currentMonth.toString()
        }))
    }, [currentMonth, currentYear])

    return (
        <>
            <Box
                className="calendar-date-filter"
            >
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                >
                    <DatePicker
                        label="Search Date"
                        value={calendarDate}
                        onChange={(newValue) => {
                            setCalendarDate(newValue);

                            if (!newValue)
                                return;

                            setCurrentMonth(newValue.toDate().getMonth() + 1);
                            setCurrentYear(newValue.toDate().getFullYear());
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </LocalizationProvider>
            </Box>
            <div
                style={{
                    height: '800px',
                    padding: myAccount.accountDetails.username === 'admin' ? '0' : '20px',
                    fontSize: '14px'
                }}>
                <Calendar
                    localizer={localizer}
                    events={event}
                    components={{
                        event: Event
                    }}
                    startAccessor="start"
                    endAccessor="end"
                    date={calendarDate?.toDate()}
                    onNavigate={(date) => {
                        setCalendarDate(dayjs(date));
                        setCurrentMonth(date.getMonth() + 1);
                        setCurrentYear(date.getFullYear());
                    }}
                />
            </div>
        </>
    )
}

export default CalendarComponent;