import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Breadcrumbs, Tabs, Tab, Chip, Autocomplete, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import Clients from '../clients';
import Rooms from '../rooms';
import ClientRooms from '../client-rooms';
import KeyIcon from '@mui/icons-material/Key';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { IMyAccountChangePassword } from '../../../../types/my-account/create';
import { showWarningToast } from '../../../../toast/warn';
import { changePassword } from '../../../../store/reducers/my-account';


const MyAccount: React.FunctionComponent = () => {

    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);

    const myAccount = useSelector((state: RootState) => state.myAccount);
    const dispatchAction = useDispatch<AppDispatch>();

    const changePasswordDataPlaceholder: IMyAccountChangePassword = {
        old_password: '',
        new_password: '',
        confirm_new_password: ''
    }
    const [changePasswordData, setChangePasswordData] = useState<IMyAccountChangePassword>(changePasswordDataPlaceholder);
    
    const changePasswordModalHandleOpen = () => setIsChangePasswordModalOpen(true);
    const changePasswordModalHandleClose = () => {
        setIsChangePasswordModalOpen(false);
        setChangePasswordData(changePasswordDataPlaceholder);
    };

    const changePasswordClickEvent = () => {
        if (changePasswordData.new_password != changePasswordData.confirm_new_password) {
            showWarningToast('New password mismatch');
            return;
        }

        dispatchAction(changePassword(changePasswordData));
    }

    useEffect(() => {

        if (myAccount.isChangePasswordSuccess)
            setIsChangePasswordModalOpen(false);

    }, [myAccount.isChangePasswordSuccess])

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        My Account
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="my-account-info-container"
            >
                <Box
                    className="my-account-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        First Name
                    </Typography>
                    <Typography>
                        {myAccount.accountDetails.first_name}
                    </Typography>
                </Box>
                <Box
                    className="my-account-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Middle Name
                    </Typography>
                    <Typography>
                        {myAccount.accountDetails.middle_name}
                    </Typography>
                </Box>
                <Box
                    className="my-account-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Last Name
                    </Typography>
                    <Typography>
                        {myAccount.accountDetails.last_name}
                    </Typography>
                </Box>
                <Box
                    className="my-account-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Contact Number
                    </Typography>
                    <Typography>
                        {myAccount.accountDetails.contact_number}
                    </Typography>
                </Box>
                <Box
                    className="my-account-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Address
                    </Typography>
                    <Typography>
                        {myAccount.accountDetails.address}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="my-account-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box
                    className="my-account-info"
                >
                    <Button sx={{ marginRight: '10px' }} onClick={changePasswordModalHandleOpen} variant="contained" color="warning" startIcon={<KeyIcon />}>
                        Change Password
                    </Button>
                </Box>
            </Box>
            <Modal
                open={isChangePasswordModalOpen}
                className="my-account-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="my-account-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Change Password
                    </Typography>

                    <Divider />

                    <Box
                        className="my-account-modal-form-controls"
                    >
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Old Password"
                            variant="outlined"
                            fullWidth
                            required
                            type="password"
                            value={changePasswordData.old_password}
                            onChange={(e) => {
                                setChangePasswordData((previousVal: IMyAccountChangePassword) => {
                                    return { ...previousVal, old_password: e.target.value };
                                })
                            }}
                            disabled={myAccount.isChangePasswordLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="New Password"
                            variant="outlined"
                            fullWidth
                            required
                            type="password"
                            value={changePasswordData.new_password}
                            onChange={(e) => {
                                setChangePasswordData((previousVal: IMyAccountChangePassword) => {
                                    return { ...previousVal, new_password: e.target.value };
                                })
                            }}
                            disabled={myAccount.isChangePasswordLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Confirm New Password"
                            variant="outlined"
                            fullWidth
                            required
                            type="password"
                            value={changePasswordData.confirm_new_password}
                            onChange={(e) => {
                                setChangePasswordData((previousVal: IMyAccountChangePassword) => {
                                    return { ...previousVal, confirm_new_password: e.target.value };
                                })
                            }}
                            disabled={myAccount.isChangePasswordLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="warning"
                            disabled={myAccount.isChangePasswordLoading}
                            onClick={changePasswordClickEvent}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: myAccount.isChangePasswordLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Save
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={changePasswordModalHandleClose}
                            disabled={myAccount.isChangePasswordLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default MyAccount;