import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, Chip, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IRoomListItem } from '../../../../types/room/list-item';
import { IRoomCreate } from '../../../../types/room/create';
import { IRoomEdit } from '../../../../types/room/edit';
import { showWarningToast } from '../../../../toast/warn';
import { AppDispatch, RootState } from '../../../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { getList, create, update, getSingleForEdit, _delete } from '../../../../store/reducers/room';
import dayjs from 'dayjs';
import toMoney from '../../../../utils/to-money';
import isWholeNumber from '../../../../utils/is-whole-number';
import getRole from '../../../../utils/get-role';
import toFixed from '../../../../utils/to-fixed';


const Rooms: React.FunctionComponent = () => {
    // Global
    const navigate = useNavigate();
    const _room = useSelector((state: RootState) => state.room);
    const dispatchAction = useDispatch<AppDispatch>();

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getList(null));
    }, []);

    useEffect(() => {
        if (_room.isRefetchList)
            dispatchAction(getList(null));

    }, [_room.isRefetchList]);


    // Create
    const createDataPlaceholder = {
        name: '',
        price: 0,
        additional_head_price: 0,
        max_additional_head: 0
    }
    const [createData, setCreateData] = useState<IRoomCreate>(createDataPlaceholder);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const createModalHandleOpen = () => setIsCreateModalOpen(true);
    const createModalHandleClose = () => {
        setCreateData(createDataPlaceholder);
        setIsCreateModalOpen(false)
    }

    const createClickEvent = () => {
        if (
            !createData.name
            || !createData.price
        ) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(create(createData));
    }

    useEffect(() => {
        if (_room.isCreateSuccess) {
            setIsCreateModalOpen(false);
            setCreateData(createDataPlaceholder);
        }

    }, [_room.isCreateSuccess]);

    // Edit
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const editModalHandleClose = () => setIsEditModalOpen(false);
    const editDataPlaceholder = {
        id: 0,
        name: '',
        price: 0,
        additional_head_price: 0,
        max_additional_head: 0
    }
    const [editData, setEditData] = useState<IRoomEdit>(editDataPlaceholder);

    const getEditData = (id: number) => {
        dispatchAction(getSingleForEdit(id));
        setIsEditModalOpen(true);
    }

    useEffect(() => {

        if (_room.isEditGetSingleSuccess) {
            setEditData(_room.editSingleData);
        }

    }, [_room.isEditGetSingleSuccess])

    const editClickEvent = () => {
        if (!editData.name
            || !editData.price
        ) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(update(editData));
    }

    useEffect(() => {
        if (_room.isUpdateSuccess)
            setIsEditModalOpen(false);

    }, [_room.isUpdateSuccess]);

    // Delete
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const deleteModalHandleClose = () => setIsDeleteModalOpen(false);
    const [deleteData, setDeleteData] = useState<IRoomListItem>();

    const openDeleteModal = (data: IRoomListItem) => {
        setDeleteData(data);
        setIsDeleteModalOpen(true);
    }

    const deleteClickEvent = () => {
        if (deleteData && deleteData.id)
            dispatchAction(_delete(deleteData.id));
    }

    useEffect(() => {
        if (!_room.isDeleteLoading)
            setIsDeleteModalOpen(false);

    }, [_room.isDeleteLoading]);

    useEffect(() => {



    }, [_room.isDeleteSuccess]);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        Rooms
                    </Link>
                </Breadcrumbs>
            </Box>
            {getRole() === 1 ?
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={createModalHandleOpen}
                >
                    Create New
                </Button>
                : null}
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="rooms-table"
            >
                {_room.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Name</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Rates</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_room.list && _room.list.length > 0
                                ?
                                _room.list.map(item => {
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.name}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.price)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Stack direction="row" spacing={2}>
                                                    <Button onClick={() => navigate(`/rooms/${item.id}`)} variant="contained" color="primary" startIcon={<VisibilityIcon />}>
                                                        View
                                                    </Button>
                                                    <Button onClick={() => getEditData(item.id)} variant="contained" color="success" startIcon={<EditIcon />}>
                                                        Edit
                                                    </Button>
                                                    <Button onClick={() => openDeleteModal(item)} variant="outlined" color="error" startIcon={<DeleteIcon />}>
                                                        Delete
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            <Modal
                open={isCreateModalOpen}
                className="rooms-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="rooms-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Create Room
                    </Typography>

                    <Divider />

                    <Box
                        className="rooms-modal-form-controls"
                    >
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.name}
                            onChange={(e) => {
                                setCreateData((previousVal: IRoomCreate) => {
                                    return { ...previousVal, name: e.target.value };
                                })
                            }}
                            disabled={_room.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Rates"
                            variant="outlined"
                            fullWidth
                            required
                            type="number"
                            value={createData.price}
                            onChange={(e) => {
                                setCreateData((previousVal: IRoomCreate) => {
                                    return { ...previousVal, price: Number(e.target.value) };
                                })
                            }}
                            disabled={_room.isCreateLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={createClickEvent}
                            disabled={_room.isCreateLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: _room.isCreateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Create
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={createModalHandleClose}
                            disabled={_room.isCreateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isEditModalOpen}
                className="rooms-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="rooms-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Update Room
                    </Typography>

                    <Divider />

                    <Box
                        className="rooms-modal-form-controls"
                    >
                        <TextField
                            InputLabelProps={!!editData.name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.name}
                            onChange={(e) => {
                                setEditData((previousVal: IRoomEdit) => {
                                    return { ...previousVal, name: e.target.value };
                                })
                            }}
                            disabled={_room.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.price ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Rates"
                            variant="outlined"
                            fullWidth
                            required
                            type="number"
                            value={editData.price}
                            onChange={(e) => {
                                setEditData((previousVal: IRoomEdit) => {
                                    return { ...previousVal, price: Number(e.target.value) };
                                })
                            }}
                            disabled={_room.isUpdateLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={editClickEvent}
                            disabled={_room.isUpdateLoading}
                            color="success"
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: _room.isUpdateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Save
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={editModalHandleClose}
                            disabled={_room.isUpdateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isDeleteModalOpen}
                className="rooms-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="rooms-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Delete Lot
                    </Typography>

                    <Divider />

                    <Box
                        className="rooms-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to delete room '{deleteData?.name}'?
                        </Typography>
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={deleteClickEvent}
                            disabled={_room.isDeleteLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: _room.isDeleteLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Delete
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={deleteModalHandleClose}
                            disabled={_room.isDeleteLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default Rooms;