import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Breadcrumbs, Tabs, Tab, Chip, Autocomplete, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import Clients from '../clients';
import Rooms from '../rooms';
import ClientRooms from '../client-rooms';
import PaymentClientRoomPayments from '../payment-client-room-payments';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { getSingle as getSingleClient } from '../../../../store/reducers/client';
import { getSingle as getSingleClientRoom, cancel } from '../../../../store/reducers/client-room';
import toMoney from '../../../../utils/to-money';
import dayjs from 'dayjs';
import toFixed from '../../../../utils/to-fixed';
import getRole from '../../../../utils/get-role';
import { IClientRoom } from '../../../../types/client-room/single';
import { ICancel } from '../../../../types/room/cancel';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
        >
            <Box sx={{ p: 3 }}>
                <Box>{children}</Box>
            </Box>
        </div>
    );
}

const SinglePaymentClientRoom: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    // Global
    const dispatchAction = useDispatch<AppDispatch>();
    const client = useSelector((state: RootState) => state.client);
    const clientRoom = useSelector((state: RootState) => state.clientRoom);

    // Client
    const { id } = useParams();

    // Get single
    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN') && id)
            dispatchAction(getSingleClient(parseInt(id)));
    }, []);

    useEffect(() => {
        if (client.isRefetchList && id)
            dispatchAction(getSingleClient(parseInt(id)));

    }, [client.isRefetchList]);

    // Client room
    const { clientRoomId } = useParams();

    // Get single
    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN') && clientRoomId)
            dispatchAction(getSingleClientRoom(parseInt(clientRoomId)));
    }, []);

    useEffect(() => {
        if ((clientRoom.isRefetchList || clientRoom.isRefetchGetSingle) && clientRoomId)
            dispatchAction(getSingleClientRoom(parseInt(clientRoomId)));

    }, [clientRoom.isRefetchList, clientRoom.isRefetchGetSingle]);

    // Cancel
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const cancelModalHandleClose = () => setIsCancelModalOpen(false);
    const [cancelData, setCancelData] = useState<ICancel>({
        room_name: '',
        date: '',
        client_room_id: 0,
        cancelled_note: '',
        is_permanently_delete: false
    });

    const openCancelModal = () => {
        setCancelData({
            room_name: '',
            date: '',
            client_room_id: clientRoom.singleData.client_room_id,
            cancelled_note: '',
            is_permanently_delete: false
        });
        setIsCancelModalOpen(true);
    }

    const cancelClickEvent = () => {
        dispatchAction(cancel(cancelData));
    }

    useEffect(() => {
        if (!clientRoom.isCancelLoading)
            setIsCancelModalOpen(false);

    }, [clientRoom.isCancelLoading]);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/payments')}
                    >
                        Payments
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate(`/payments/${id}`)}
                    >
                        {client.singleData.first_name} {client.singleData.middle_name} {client.singleData.last_name}
                    </Link>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        Room {clientRoom.singleData.room_name} - {dayjs(clientRoom.singleData.date).format('MMMM D, YYYY')}
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="single-payment-info-container"
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: client.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        First Name
                    </Typography>
                    <Typography>
                        {client.singleData.first_name}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Middle Name
                    </Typography>
                    <Typography>
                        {client.singleData.middle_name}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Last Name
                    </Typography>
                    <Typography>
                        {client.singleData.last_name}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Contact Number
                    </Typography>
                    <Typography>
                        {client.singleData.contact_number}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Address
                    </Typography>
                    <Typography>
                        {client.singleData.address}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: clientRoom.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Suite's Name
                    </Typography>
                    <Typography>
                        {getRole() === 1 ?
                            <Link onClick={() => navigate(`/rooms/${clientRoom.singleData.room_id}`)} underline="hover" sx={{ cursor: 'pointer' }}>
                                {clientRoom.singleData.room_name}
                            </Link>
                            : clientRoom.singleData.room_name}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Rates
                    </Typography>
                    <Typography>
                        {toMoney(clientRoom.singleData.current_price)}
                    </Typography>
                </Box>    
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: clientRoom.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Excess Guest
                    </Typography>
                    <Typography>
                        {clientRoom.singleData.additional_head}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                       Additional Amount For Excess Guest
                    </Typography>
                    <Typography>
                        {toMoney(clientRoom.singleData.additional_amount_for_excess_guest)}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                       Discount
                    </Typography>
                    <Typography>
                        {toMoney(clientRoom.singleData.discount)}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Number of Days Stayed
                    </Typography>
                    <Typography>
                        {clientRoom.singleData.days}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Total Amount
                    </Typography>
                    <Typography>
                        {toMoney(clientRoom.singleData.total_price)}
                    </Typography>
                </Box>
                
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: clientRoom.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: '700',
                                marginRight: '5px'
                            }}
                        >
                            Total Amount Payed
                        </Typography>
                        <Typography
                            fontSize="small"
                            color="gray"
                        >
                            ({toFixed(clientRoom.singleData.total_payed / clientRoom.singleData.total_price * 100, 2)}%)
                        </Typography>
                    </Box>
                    <Typography>
                        {toMoney(clientRoom.singleData.total_payed)}
                    </Typography>
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        display: clientRoom.isGetSingleLoading ? 'flex' : 'none',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        height: '100%',
                        width: '100%',
                        zIndex: 3,
                        borderRadius: '10px'
                    }}>
                        <CircularProgress />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: '700',
                                marginRight: '5px'
                            }}
                            color="error"
                        >
                            Balance
                        </Typography>
                        <Typography
                            fontSize="small"
                            color="gray"
                        >
                            ({toFixed(clientRoom.singleData.balance / clientRoom.singleData.total_price * 100, 2)}%)
                        </Typography>
                    </Box>
                    <Typography>
                        {toMoney(clientRoom.singleData.balance)}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: clientRoom.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Date
                    </Typography>
                    <Chip label={clientRoom.singleData.date !== clientRoom.singleData.date_end ? `${dayjs(clientRoom.singleData.date).format('MMMM D, YYYY')} - ${dayjs(clientRoom.singleData.date_end).format('MMMM D, YYYY')}` : dayjs(clientRoom.singleData.date).format('MMMM D, YYYY')} variant="outlined" color='primary' />
                </Box>
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: clientRoom.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Status
                    </Typography>
                    {
                        Number(clientRoom.singleData.balance) === 0 ? <Chip label='Paid' color="primary" /> :
                        <Chip label={clientRoom.singleData.is_cancelled ? 'Cancelled' : 'Active'} color={clientRoom.singleData.is_cancelled ? 'error' : 'success'} />
                    }
                </Box>
            </Box>
            <Box
                className="single-payment-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: clientRoom.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box
                    className="single-payment-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Notes of Cancelled
                    </Typography>
                    <Typography>
                        {clientRoom.singleData.cancelled_note ? clientRoom.singleData.cancelled_note : 'N/A'}
                    </Typography>
                </Box>
            </Box>
            <Button sx={{ marginTop: '20px', display: getRole() === 1 ? 'default' : 'none' }} disabled={clientRoom.singleData.is_cancelled} onClick={openCancelModal} variant="contained" color="error" startIcon={<HighlightOffIcon />}>
                Cancel
            </Button>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginTop: '20px'
                }}

            >
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                >
                    <Tab label={`Payments (${clientRoom.paymentListPagination.overallTotal})`} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <PaymentClientRoomPayments is_paid={Number(clientRoom.singleData.balance) === 0} client_room_id={clientRoomId ? parseInt(clientRoomId) : 0} />
            </TabPanel>
            <Modal
                open={isCancelModalOpen}
                className="single-payment-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="single-payment-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Cancel
                    </Typography>

                    <Divider />

                    <Box
                        className="single-payment-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to cancel client booking 'Room {clientRoom.singleData.room_name} - {dayjs(clientRoom.singleData.date).format('MMMM D, YYYY')}'?
                        </Typography>
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            label="Note"
                            variant="outlined"
                            fullWidth
                            multiline
                            maxRows={8}
                            minRows={8}
                            value={cancelData.cancelled_note}
                            onChange={(e) => {
                                setCancelData((previousVal:ICancel) => {
                                    return { ...previousVal, cancelled_note: e.target.value };
                                })
                            }}
                            disabled={clientRoom.isCancelLoading}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cancelData.is_permanently_delete}
                                    onChange={(e) => {
                                        setCancelData((previousVal: ICancel) => {
                                            return { ...previousVal, is_permanently_delete: e.target.checked };
                                        })
                                    }}
                                />
                            } label="Permanently Delete" />
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={cancelClickEvent}
                            disabled={clientRoom.isCancelLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: clientRoom.isCancelLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Yes
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={cancelModalHandleClose}
                            disabled={clientRoom.isCancelLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default SinglePaymentClientRoom;