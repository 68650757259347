import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ILogin } from "../../types/login";
import axios from "axios";
import { showErrorToast } from "../../toast/error";

export const login = createAsyncThunk(
    'auth/login',
    async ({ username, password }: ILogin, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await axios.post(
                process.env.REACT_APP_API_URL!.toString() + '/api/auth',
                {
                    username: username,
                    password: password
                }
            );

            if (data.success === false) {
                showErrorToast(data.message);
                return {
                    success: false
                }
            }

            localStorage.setItem('ACCESS_TOKEN', data.token);
            localStorage.setItem('REFRESH_TOKEN', data.refresh_token);

            return fulfillWithValue(data);
        } catch (err) {
            showErrorToast('Something went wrong');
            return fulfillWithValue({
                success: false
            })
        }
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isLoginLoading: false,
        isLoginSuccess: false,
        isRefreshTokenExpired: false,
        redirect: "/calendar",
    },
    reducers: {
        setRefreshTokenExpire: state => {
            state.isRefreshTokenExpired = true;
        },
        logout: state => {
            localStorage.removeItem('ACCESS_TOKEN');
            localStorage.removeItem('REFRESH_TOKEN');
            localStorage.removeItem('ACCOUNT_DETAILS');
            state.isLoginLoading = false;
            state.isLoginSuccess = false;
        }
    },
    extraReducers: builder => {

        builder

            // auth
            .addCase(login.pending, (state) => {
                state.isRefreshTokenExpired = false;
                state.isLoginSuccess = false;
                state.isLoginLoading = true;
            })

            .addCase(login.fulfilled, (state, action) => {

                state.isLoginSuccess = action.payload.success;
                
                state.isLoginLoading = false;
            })
    }
});

const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

export const refreshToken = async () => {
    try {
        const currentAccessToken = localStorage.getItem('ACCESS_TOKEN');

        if (localStorage.getItem('REFRESHING_TOKEN')) {
            while (true) {
                if (!localStorage.getItem('REFRESHING_TOKEN') && currentAccessToken !== localStorage.getItem('ACCESS_TOKEN'))
                    break;

                await sleep(300);
            }

            return {
                success: localStorage.getItem('REFRESHING_TOKEN_SUCCESS') === 'true'
            }
        }

        localStorage.setItem('REFRESHING_TOKEN', 'true');
        localStorage.setItem('REFRESHING_TOKEN_SUCCESS', 'false');

        const { data } = await axios.post(
            process.env.REACT_APP_API_URL!.toString() + '/api/auth/refresh',
            {
                refresh_token: localStorage.getItem('REFRESH_TOKEN'),
            }
        );

        if (data.success === false) {
            showErrorToast('Login expired, please re-login');
            localStorage.removeItem('ACCESS_TOKEN');
            localStorage.removeItem('REFRESH_TOKEN');
            localStorage.removeItem('ACCOUNT_DETAILS');
            localStorage.removeItem('REFRESHING_TOKEN');
            return {
                success: false
            }
        }

        localStorage.setItem('ACCESS_TOKEN', data.token);
        localStorage.setItem('REFRESH_TOKEN', data.refresh_token);
        localStorage.removeItem('REFRESHING_TOKEN');
        localStorage.setItem('REFRESHING_TOKEN_SUCCESS', 'true');

        return {
            success: true
        }
    }
    catch (err) {
        localStorage.removeItem('REFRESHING_TOKEN');
        return {
            success: false
        }
    }
}

export const {
    setRefreshTokenExpire,
    logout
} = authSlice.actions;

export default authSlice.reducer;