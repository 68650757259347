import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import IndexPage from './pages/index';
import DashboardPage from './pages/dashboard';
import './App.css';
import Receipt from './components/prints/receipt';

const App: React.FunctionComponent = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<IndexPage />} />
          <Route path='/payment-options' element={<DashboardPage page="payment-options" />} />
          <Route path='/clients' element={<DashboardPage page="clients" />} />
          <Route path='/clients/:id' element={<DashboardPage page="single-client" />} />
          <Route path='/rooms/' element={<DashboardPage page="rooms" />} />
          <Route path='/rooms/:roomId' element={<DashboardPage page="single-room" />} />
          <Route path='/payments' element={<DashboardPage page="payments" />} />
          <Route path='/payments/:id' element={<DashboardPage page="single-payment" />} />
          <Route path='/payments/:id/room/:clientRoomId' element={<DashboardPage page="single-payment-client-room" />} />
          <Route path='/reports/' element={<DashboardPage page="reports" />} />
          <Route path='/my-account' element={<DashboardPage page="my-account" />} />
          <Route path='/receipt/' element={<Receipt />} />
          <Route path='/calendar/' element={<DashboardPage page="calendar" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
