import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Breadcrumbs, Tabs, Tab, Chip, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import Clients from '../clients';
import Rooms from '../rooms';
import RoomClients from '../room-clients';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import room, { _delete, getSingle, getSingleForEdit, update } from '../../../../store/reducers/room';
import { IRoomEdit } from '../../../../types/room/edit';
import { showWarningToast } from '../../../../toast/warn';
import { IRoomListItem } from '../../../../types/room/list-item';
import { IRoom } from '../../../../types/room/single';
import toMoney from '../../../../utils/to-money';
import isWholeNumber from '../../../../utils/is-whole-number';
import toFixed from '../../../../utils/to-fixed';
import getRole from '../../../../utils/get-role';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
        >
            <Box sx={{ p: 3 }}>
                <Box>{children}</Box>
            </Box>
        </div>
    );
}

const SingleRoom: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const { id, roomId } = useParams();

    // Global
    const dispatchAction = useDispatch<AppDispatch>();
    const _room = useSelector((state: RootState) => state.room);
    const roomClient = useSelector((state: RootState) => state.roomClient);

    // Get single
    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN') && roomId)
            dispatchAction(getSingle(parseInt(roomId)));
    }, []);

    useEffect(() => {
        if (_room.isRefetchList && roomId)
            dispatchAction(getSingle(parseInt(roomId)));

    }, [_room.isRefetchList]);

    // Edit
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const editModalHandleClose = () => setIsEditModalOpen(false);
    const editDataPlaceholder = {
        id: 0,
        name: '',
        price: 0
    }
    const [editData, setEditData] = useState<IRoomEdit>(editDataPlaceholder);

    const getEditData = () => {
        if (!roomId)
            return;

        dispatchAction(getSingleForEdit(parseInt(roomId)));
        setIsEditModalOpen(true);
    }

    useEffect(() => {

        if (_room.isEditGetSingleSuccess)
            setEditData(_room.editSingleData);

    }, [_room.isEditGetSingleSuccess])

    const editClickEvent = () => {
        if (!editData.name
            || !editData.price
        ) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(update(editData));
    }

    useEffect(() => {
        if (_room.isUpdateSuccess)
            setIsEditModalOpen(false);

    }, [_room.isUpdateSuccess]);

    // Delete
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const deleteModalHandleClose = () => setIsDeleteModalOpen(false);
    const [deleteData, setDeleteData] = useState<IRoom>();

    const openDeleteModal = () => {

        if (!_room.singleData)
            return;

        setDeleteData(_room.singleData);
        setIsDeleteModalOpen(true);
    }

    const deleteClickEvent = () => {
        if (deleteData && deleteData.id)
            dispatchAction(_delete(deleteData.id));
    }

    useEffect(() => {
        if (!_room.isDeleteLoading)
            setIsDeleteModalOpen(false);

    }, [_room.isDeleteLoading]);

    useEffect(() => {

        if (_room.isDeleteSuccess)
            navigate(`/areas/${id}`)

    }, [_room.isDeleteSuccess]);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/rooms')}
                    >
                        Rooms
                    </Link>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                       {_room.singleData.name}
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="single-room-info-container"
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: _room.isGetSingleLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                {getRole() === 1 ?
                <Box
                    className="single-room-info-edit-button"
                >
                    <Button className="info-button" sx={{ marginRight: '10px' }} onClick={getEditData} variant="contained" color="success" startIcon={<EditIcon />}>
                        Edit
                    </Button>
                    <Button className="info-button" onClick={openDeleteModal} variant="contained" color="error" startIcon={<DeleteIcon />}>
                        Delete
                    </Button>
                </Box>
                : null }
                <Box
                    className="single-room-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Name
                    </Typography>
                    <Typography>
                        {_room.singleData.name}
                    </Typography>
                </Box>
                <Box
                    className="single-room-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        Rates
                    </Typography>
                    <Typography>
                        {toMoney(_room.singleData.price)}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginTop: '20px'
                }}

            >
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                >
                    <Tab label={`Clients (${roomClient.list.length})`} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <RoomClients room_id={roomId ? parseInt(roomId) : 0}/>
            </TabPanel>
            <Modal
                open={isEditModalOpen}
                className="rooms-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="rooms-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Update Room
                    </Typography>

                    <Divider />

                    <Box
                        className="rooms-modal-form-controls"
                    >
                        <TextField
                            InputLabelProps={!!editData.name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.name}
                            onChange={(e) => {
                                setEditData((previousVal: IRoomEdit) => {
                                    return { ...previousVal, name: e.target.value };
                                })
                            }}
                            disabled={_room.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.price ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Rates"
                            variant="outlined"
                            fullWidth
                            required
                            type="number"
                            value={editData.price}
                            onChange={(e) => {
                                setEditData((previousVal: IRoomEdit) => {
                                    return { ...previousVal, price: Number(e.target.value) };
                                })
                            }}
                            disabled={_room.isUpdateLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={editClickEvent}
                            disabled={_room.isUpdateLoading}
                            color="success"
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: _room.isUpdateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Save
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={editModalHandleClose}
                            disabled={_room.isUpdateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isDeleteModalOpen}
                className="rooms-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="rooms-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Delete Lot
                    </Typography>

                    <Divider />

                    <Box
                        className="rooms-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to delete room '{deleteData?.name}'?
                        </Typography>
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={deleteClickEvent}
                            disabled={_room.isDeleteLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: _room.isDeleteLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Delete
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={deleteModalHandleClose}
                            disabled={_room.isDeleteLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default SingleRoom;