import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IPaymentOptionListItem } from '../../types/payment-option/list-item';
import { IPagination } from '../../types/pagination';
import axios from 'axios';
import { logout, refreshToken, setRefreshTokenExpire } from './auth';
import { showErrorToast } from '../../toast/error';
import { IPaymentOptionCreate } from '../../types/payment-option/create';
import { IPaymentOption } from '../../types/payment-option/single';
import { IPaymentOptionEdit } from '../../types/payment-option/edit';
import { showSuccessToast } from '../../toast/success';
import { IAutocomplete } from '../../types/autocomplete';

interface IListParams {
    page: number,
    search: string,
    addToCurrent?: boolean
}

const getListQuery = async (queryParams: IListParams) => {
    try {
        const paramsData = [
            ['page', queryParams.page.toString()]
        ];

        if (queryParams.search)
            paramsData.push(['search', queryParams.search]);

        const params = new URLSearchParams(paramsData);

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + '/api/payment-type',
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                },
                params
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const getList = createAsyncThunk(
    'payment-option/list',
    async (queryParams: IListParams, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            

            let result = await getListQuery(queryParams);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {
                    
                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,
                        
                    });
                }
                else
                    result = await getListQuery(queryParams);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const create = createAsyncThunk(
    'payment-option/create',
    async (queryParams: IPaymentOptionCreate, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {

                    const { data } = await axios.post(
                        process.env.REACT_APP_API_URL!.toString() + '/api/payment-type',
                        {
                            name: queryParams.name
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            }

            let result = await query();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {
                    
                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,
                        
                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }
            else {
                showSuccessToast('Successfully created');
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

const getSingleQuery = async (id: number) => {
    try {

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + `/api/payment-type/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                }
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const update = createAsyncThunk(
    'payment-option/update',
    async (queryParams: IPaymentOptionEdit, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {

                    const { data } = await axios.patch(
                        process.env.REACT_APP_API_URL!.toString() + `/api/payment-type/${queryParams.id}`,
                        {
                            name: queryParams.name
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            }

            let result = await query();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {
                    
                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,
                        
                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }
            else {
                showSuccessToast('Successfully updated');
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const _delete = createAsyncThunk(
    'payment-option/delete',
    async (id: number, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {

                    const { data } = await axios.delete(
                        process.env.REACT_APP_API_URL!.toString() + `/api/payment-type/${id}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            }

            let result = await query();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {
                    
                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,
                        
                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }
            else {
                showSuccessToast('Successfully deleted');
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const getSingleForEdit = createAsyncThunk(
    'payment-option/getSingleForEdit',
    async (id: number, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {


            let result = await getSingleQuery(id);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {
                    
                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,
                    });
                }
                else
                    result = await getSingleQuery(id);
            }
            
            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const getAutocompleteList = createAsyncThunk(
    'payment-option/getAutocompleteList',
    async (queryParams: IListParams, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            
            let result = await getListQuery(queryParams);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {
                    
                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,
                        
                    });
                }
                else
                    result = await getListQuery(queryParams);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            result.addToCurrent = queryParams.addToCurrent;

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const paymentOptionSlice = createSlice({
    name: 'payment-option',
    initialState: {

        // List
        list: [] as IPaymentOptionListItem[],
        listPagination: {} as IPagination,
        isListLoading: false,
        isRefetchList: false,

        // Create
        isCreateLoading: false,
        isCreateSuccess: false,

        // Edit
        isEditGetSingleLoading: false,
        isEditGetSingleSuccess: false,
        editSingleData: {} as IPaymentOption,
        isUpdateLoading: false,
        isUpdateSuccess: false,

        // Delete
        isDeleteLoading: false,
        isDeleteSuccess: false,

        // Autocomplete List
        autocompleteList: [] as IAutocomplete[],
        autocompleteListPagination: {} as IPagination,
        isAutocompleteListLoading: false
    },
    reducers: {
        emptyAutocompleteList: state => {
            state.autocompleteList = [];
            state.autocompleteListPagination = {} as IPagination;
        },
    },
    extraReducers: builder => {

        builder

            // list
            .addCase(getList.pending, state => {
                state.isListLoading = true;
            })
            .addCase(getList.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data) {
                    state.list = payload.data;
                    state.listPagination = payload;
                }

                state.isListLoading = false;
                state.isRefetchList = false;
            })

            // List for client autocomplete
            .addCase(getAutocompleteList.pending, state => {
                state.isAutocompleteListLoading = true;
            })
            .addCase(getAutocompleteList.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data) {

                    if(!payload.addToCurrent)
                        state.autocompleteList = [];

                    for(let i = 0; i < payload.data.length; i++)
                        state.autocompleteList.push({
                            label: `${payload.data[i].name}`,
                            value: payload.data[i].id
                        });

                    state.autocompleteListPagination = payload;
                }

                state.isAutocompleteListLoading = false;
            })

            // create
            .addCase(create.pending, state => {
                state.isRefetchList = false;
                state.isCreateSuccess = false;
                state.isCreateLoading = true;
            })
            .addCase(create.fulfilled, (state, { payload }) => {
                state.isCreateSuccess = payload.success;
                state.isRefetchList = payload.success;
                state.isCreateLoading = false;
            })

            // edit get single
            .addCase(getSingleForEdit.pending, state => {
                state.isEditGetSingleSuccess = false;
                state.isEditGetSingleLoading = true;
            })
            .addCase(getSingleForEdit.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data)
                    state.editSingleData = payload.data;

                state.isEditGetSingleSuccess = payload.success;
                state.isEditGetSingleLoading = false;
            })

            // update
            .addCase(update.pending, state => {
                state.isRefetchList = false;
                state.isUpdateSuccess = false;
                state.isUpdateLoading = true;
            })
            .addCase(update.fulfilled, (state, { payload }) => {
                state.isUpdateSuccess = payload.success;
                state.isRefetchList = payload.success;
                state.isUpdateLoading = false;
            })

            // delete
            .addCase(_delete.pending, state => {
                state.isRefetchList = false;
                state.isDeleteSuccess = false;
                state.isDeleteLoading = true;
            })
            .addCase(_delete.fulfilled, (state, { payload }) => {
                state.isDeleteSuccess = payload.success;
                state.isRefetchList = payload.success;
                state.isDeleteLoading = false;
            })
    }
});

export default paymentOptionSlice.reducer;

export const {
    emptyAutocompleteList
} = paymentOptionSlice.actions;