export default (number:number | string) => {
    if(Number.isNaN(number))
        return '0.00';

    let data = Number(Number(number).toFixed(2)).toLocaleString();

    if(!data.includes('.'))
        data += '.00';

    return data;
}