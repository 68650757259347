import { Button, CircularProgress, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import './styles.css';
import amarantaSuitesLogo from '../../assets/imgs/logo.png'
import { showWarningToast } from '../../toast/warn';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../store/reducers/auth';
import { AppDispatch, RootState } from '../../store/store';

const Index: React.FunctionComponent = () => {

    const navigate = useNavigate();
    const dispatchAction = useDispatch<AppDispatch>();
    const auth = useSelector((state: RootState) => state.auth);

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const loginClickEvent = () => {
        if (!username || !password) {
            showWarningToast('Please fillup all fields')
            return;
        }

        dispatchAction(login({
            username,
            password
        }));
    }

    const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === 13) {
            loginClickEvent();
        }
    }

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            navigate(auth.redirect);
    })

    useEffect(() => {

        if (auth.isLoginSuccess && localStorage.getItem('ACCESS_TOKEN'))
            navigate(auth.redirect);

    }, [auth.isLoginSuccess])

    return (
        <>
            <Box
                className="index-container"
            >
                <Box
                    component="form"
                    className="index-form-container"
                >
                    <img
                        className="index-logo"
                        alt="amaranta suites logo"
                        src={amarantaSuitesLogo}
                    />
                    <TextField
                        sx={{
                            display: 'block',
                            marginBottom: '10px'
                        }}
                        label="Username"
                        variant="outlined"
                        fullWidth
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyUp={handleKeypress}
                        disabled={auth.isLoginLoading || auth.isLoginSuccess}
                    />
                    <TextField
                        sx={{
                            display: 'block',
                            marginBottom: '10px'
                        }}
                        type="password"
                        label="Password"
                        variant="outlined"
                        fullWidth
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyUp={handleKeypress}
                        disabled={auth.isLoginLoading || auth.isLoginSuccess}
                    />
                    <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={loginClickEvent}
                        disabled={auth.isLoginLoading || auth.isLoginSuccess}
                    >
                        <CircularProgress
                            size="1em"
                            sx={{
                                display: auth.isLoginLoading || auth.isLoginSuccess ? 'inherit' : 'none',
                                marginRight: '10px'
                            }}
                        />
                        Login
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default Index