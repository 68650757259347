import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IClientListItem } from '../../../../types/client/list-item';
import { IClientCreate } from '../../../../types/client/create';
import { IClientEdit } from '../../../../types/client/edit';
import { showWarningToast } from '../../../../toast/warn';
import { AppDispatch, RootState } from '../../../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { getList, create, update, getSingleForEdit, _delete } from '../../../../store/reducers/client';
import { IAutocomplete } from '../../../../types/autocomplete';
import getRole from '../../../../utils/get-role';


const Clients: React.FunctionComponent = () => {
    // Global
    const navigate = useNavigate();
    const client = useSelector((state: RootState) => state.client);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getList({
            page: value,
            search: search
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getList({
                page: 1,
                search: ''
            }));
    }, []);

    useEffect(() => {
        if (client.isRefetchList)
            dispatchAction(getList({
                page,
                search
            }));

    }, [client.isRefetchList]);

    const searchList = () => {
        dispatchAction(getList({
            page: 1,
            search: search
        }));
    }

    const searchHandleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSearch(e.target.value);
    }

    const searchKeyPressHandle = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13) {
            searchList();
        }
    }

    // Create
    const createDataPlaceholder = {
        first_name: '',
        middle_name: '',
        last_name: '',
        contact_number: '',
        address: ''
    }
    const [createData, setCreateData] = useState<IClientCreate>(createDataPlaceholder);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    const createModalHandleClose = () => {
        setCreateData(createDataPlaceholder);
        setIsCreateModalOpen(false);
    }

    const openCreateModal = () => {
        setIsCreateModalOpen(true);
    }

    const createClickEvent = () => {
        if (
            !createData.first_name
            || !createData.middle_name
            || !createData.last_name
            || !createData.contact_number
            || !createData.address
        ) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(create(createData));
    }

    useEffect(() => {
        if (client.isCreateSuccess) {
            setIsCreateModalOpen(false);
            setCreateData(createDataPlaceholder);
        }

    }, [client.isCreateSuccess]);


    // Edit
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const editModalHandleClose = () => setIsEditModalOpen(false);
    const editDataPlaceholder = {
        id: 0,
        first_name: '',
        middle_name: '',
        last_name: '',
        contact_number: '',
        address: ''
    }
    const [editData, setEditData] = useState<IClientEdit>(editDataPlaceholder);

    const getEditData = (id: number) => {
        dispatchAction(getSingleForEdit(id));
        setIsEditModalOpen(true);
    }

    useEffect(() => {

        if (client.isEditGetSingleSuccess)
            setEditData(client.editSingleData);

    }, [client.isEditGetSingleSuccess])

    const editClickEvent = () => {
        if (
            !editData.first_name
            || !editData.middle_name
            || !editData.last_name
            || !editData.contact_number
            || !editData.address
        ) {
            showWarningToast('Please fill up all fields');
            return;
        }

        dispatchAction(update(editData));
    }

    useEffect(() => {
        if (client.isUpdateSuccess)
            setIsEditModalOpen(false);

    }, [client.isUpdateSuccess]);

    

    // Delete
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const deleteModalHandleClose = () => setIsDeleteModalOpen(false);
    const [deleteData, setDeleteData] = useState<IClientListItem>();

    const openDeleteModal = (data: IClientListItem) => {
        setDeleteData(data);
        setIsDeleteModalOpen(true);
    }

    const deleteClickEvent = () => {
        if (deleteData && deleteData.id)
            dispatchAction(_delete(deleteData.id));
    }

    useEffect(() => {
        if (!client.isDeleteLoading)
            setIsDeleteModalOpen(false);

    }, [client.isDeleteLoading]);

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        Clients
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="clients-search-container"
            >
                <FormControl
                    variant="outlined"
                    className="clients-search-input"
                    fullWidth
                >
                    <InputLabel>Search name</InputLabel>
                    <OutlinedInput
                        label="Search name"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={searchList}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={searchHandleChange}
                        value={search}
                        onKeyUp={searchKeyPressHandle}
                    />
                </FormControl>
            </Box>
            {getRole() === 1 ?
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={openCreateModal}
                >
                    Create New
                </Button>
                : null}
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="clients-table"
            >
                {client.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>First Name</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Middle Name</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Last Name</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Contact #</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Address</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {client.list && client.list.length > 0
                                ?
                                client.list.map(item => {
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.first_name}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.middle_name}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.last_name}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.contact_number}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.address}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Stack direction="row" spacing={2}>
                                                    <Button onClick={() => navigate(`/clients/${item.id}`)} variant="contained" color="primary" startIcon={<VisibilityIcon />}>
                                                        View
                                                    </Button>
                                                    {getRole() === 1 ?
                                                        <>
                                                            <Button onClick={() => getEditData(item.id)} variant="contained" color="success" startIcon={<EditIcon />}>
                                                                Edit
                                                            </Button>
                                                            <Button onClick={() => openDeleteModal(item)} variant="outlined" color="error" startIcon={<DeleteIcon />}>
                                                                Delete
                                                            </Button>
                                                        </>
                                                        : null}
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            {!client.isListLoading && client.listPagination && client.listPagination.total > 0 ?
                <Box
                    className="clients-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                               marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {client.listPagination.fromData} - {client.listPagination.toData} out of {client.listPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={client.listPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}

            <Modal
                open={isCreateModalOpen}
                className="clients-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="clients-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Create Client
                    </Typography>

                    <Divider />

                    <Box
                        className="clients-modal-form-controls"
                    >
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.first_name}
                            onChange={(e) => {
                                setCreateData((previousVal: IClientCreate) => {
                                    return { ...previousVal, first_name: e.target.value };
                                })
                            }}
                            disabled={client.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Middle Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.middle_name}
                            onChange={(e) => {
                                setCreateData((previousVal: IClientCreate) => {
                                    return { ...previousVal, middle_name: e.target.value };
                                })
                            }}
                            disabled={client.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.last_name}
                            onChange={(e) => {
                                setCreateData((previousVal: IClientCreate) => {
                                    return { ...previousVal, last_name: e.target.value };
                                })
                            }}
                            disabled={client.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Contact Number"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.contact_number}
                            onChange={(e) => {
                                setCreateData((previousVal: IClientCreate) => {
                                    return { ...previousVal, contact_number: e.target.value };
                                })
                            }}
                            disabled={client.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Address"
                            variant="outlined"
                            fullWidth
                            required
                            value={createData.address}
                            onChange={(e) => {
                                setCreateData((previousVal: IClientCreate) => {
                                    return { ...previousVal, address: e.target.value };
                                })
                            }}
                            disabled={client.isCreateLoading}
                            multiline
                            minRows={5}
                            maxRows={5}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={createClickEvent}
                            disabled={client.isCreateLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: client.isCreateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Create
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={createModalHandleClose}
                            disabled={client.isCreateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isEditModalOpen}
                className="clients-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="clients-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Update Client
                    </Typography>

                    <Divider />

                    <Box
                        className="clients-modal-form-controls"
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: client.isEditGetSingleLoading ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                            height: '100%',
                            width: '100%',
                            zIndex: 3,
                            borderRadius: '10px'
                        }}>
                            <CircularProgress />
                        </Box>
                        <TextField
                            InputLabelProps={!!editData.first_name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.first_name}
                            onChange={(e) => {
                                setEditData((previousVal: IClientEdit) => {
                                    return { ...previousVal, first_name: e.target.value };
                                })
                            }}
                            disabled={client.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.middle_name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Middle Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.middle_name}
                            onChange={(e) => {
                                setEditData((previousVal: IClientEdit) => {
                                    return { ...previousVal, middle_name: e.target.value };
                                })
                            }}
                            disabled={client.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.last_name ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.last_name}
                            onChange={(e) => {
                                setEditData((previousVal: IClientEdit) => {
                                    return { ...previousVal, last_name: e.target.value };
                                })
                            }}
                            disabled={client.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.contact_number ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Contact Number"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.contact_number}
                            onChange={(e) => {
                                setEditData((previousVal: IClientEdit) => {
                                    return { ...previousVal, contact_number: e.target.value };
                                })
                            }}
                            disabled={client.isUpdateLoading}
                        />
                        <TextField
                            InputLabelProps={!!editData.address ? { shrink: true } : {}}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Address"
                            variant="outlined"
                            fullWidth
                            required
                            value={editData.address}
                            onChange={(e) => {
                                setEditData((previousVal: IClientEdit) => {
                                    return { ...previousVal, address: e.target.value };
                                })
                            }}
                            disabled={client.isUpdateLoading}
                            multiline
                            minRows={5}
                            maxRows={5}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={editClickEvent}
                            disabled={client.isUpdateLoading}
                            color="success"
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: client.isUpdateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Save
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={editModalHandleClose}
                            disabled={client.isUpdateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isDeleteModalOpen}
                className="clients-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="clients-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Delete Client
                    </Typography>

                    <Divider />

                    <Box
                        className="clients-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to delete client '{deleteData?.first_name} {deleteData?.middle_name} {deleteData?.last_name}'?
                        </Typography>
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={deleteClickEvent}
                            disabled={client.isDeleteLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: client.isDeleteLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Delete
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={deleteModalHandleClose}
                            disabled={client.isDeleteLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default Clients;