import { Box, Button, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import './receipt.css';
import { IClientRoomPaymentListItem } from "../../types/client-room/payment-list-item";
import dayjs from "dayjs";
import toMoney from "../../utils/to-money";
import getRole from "../../utils/get-role";

const Receipt: React.FunctionComponent = () => {

    const componentRef = useRef(null);

    const [data, setData] = useState<IClientRoomPaymentListItem[]>([]);

    useEffect(() => {

        const printData = localStorage.getItem('print');

        if (!printData)
            return;

        const printDataJSON = JSON.parse(printData);

        if (Array.isArray(printDataJSON)) {
            setData(printDataJSON);
        }
        else {
            setData([printDataJSON]);
        }

    }, [])

    return (
        <>
            {getRole() === 1 || getRole() === 2 ?

                data.map((printData, i) => {
                    return <div key={i}
                        className="receipt-container"
                        ref={componentRef}>
                        <div className="receipt-content">
                            <Typography
                                sx={{
                                    marginBottom: '10px'
                                }}
                            >Amaranta Beach Resort</Typography>
                            <Typography
                                sx={{
                                    marginBottom: '30px'
                                }}
                                variant="h3"
                            >
                                Receipt
                            </Typography>
                            <Box
                                sx={{
                                    textAlign: 'left',
                                    border: '1px solid black',
                                    padding: '20px'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        marginBottom: '10px'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            marginRight: '10px',
                                            fontWeight: 700,
                                            width: '200px'
                                        }}
                                    >
                                        Payment Date:
                                    </Typography>
                                    <Typography
                                        sx={{
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        {dayjs(printData.payment_date).format('MMMM D, YYYY hh:mm A')}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        marginBottom: '10px'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            marginRight: '10px',
                                            fontWeight: 700,
                                            width: '200px'
                                        }}
                                    >
                                        Name:
                                    </Typography>
                                    <Typography
                                        sx={{
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        {printData.client_first_name} {printData.client_middle_name} {printData.client_last_name}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        marginBottom: '10px'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            marginRight: '10px',
                                            fontWeight: 700,
                                            width: '200px'
                                        }}
                                    >
                                        Amount:
                                    </Typography>
                                    <Typography
                                        sx={{
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        {toMoney(printData.amount)}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        marginBottom: '10px'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            marginRight: '10px',
                                            fontWeight: 700,
                                            width: '200px'
                                        }}
                                    >
                                        Cash:
                                    </Typography>
                                    <Typography
                                        sx={{
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        {toMoney(printData.cash)}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        marginBottom: '10px'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            marginRight: '10px',
                                            fontWeight: 700,
                                            width: '200px'
                                        }}
                                    >
                                        Date:
                                    </Typography>
                                    <Typography
                                        sx={{
                                            textDecoration: 'underline',

                                        }}
                                    >
                                        {dayjs(printData.date).format('MMMM D, YYYY')} - {dayjs(printData.date_end).format('MMMM D, YYYY')}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        marginBottom: '10px'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            marginRight: '10px',
                                            fontWeight: 700,
                                            width: '200px'
                                        }}
                                    >
                                        Room:
                                    </Typography>
                                    <Typography
                                        sx={{
                                            textDecoration: 'underline',

                                        }}
                                    >
                                        {printData.room_name}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    marginTop: '30px',
                                    pageBreakAfter: i !== data.length-1 ? 'always' : 'none'
                                }}
                            >
                                <Typography
                                    sx={{
                                        marginRight: '10px',
                                        fontWeight: 700
                                    }}
                                >
                                    Print Date:
                                </Typography>
                                <Typography>
                                    {dayjs(Date.now()).format('MMMM D, YYYY hh:mm A')}
                                </Typography>
                            </Box>
                        </div>
                    </div>
                })
                : null}
        </>
    )
}

export default Receipt;