import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Switch, Breadcrumbs, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyIcon from '@mui/icons-material/Key';
import { IClientListItem } from '../../../../types/client/list-item';
import { IClientCreate } from '../../../../types/client/create';
import { IClientEdit } from '../../../../types/client/edit';
import { showWarningToast } from '../../../../toast/warn';
import { AppDispatch, RootState } from '../../../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { getList, create, update, getSingleForEdit, _delete } from '../../../../store/reducers/client';
import { IAutocomplete } from '../../../../types/autocomplete';
import getRole from '../../../../utils/get-role';


const Payments: React.FunctionComponent = () => {
    // Global
    const navigate = useNavigate();
    const client = useSelector((state: RootState) => state.client);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getList({
            page: value,
            search: search
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getList({
                page: 1,
                search: ''
            }));
    }, []);

    useEffect(() => {
        if (client.isRefetchList)
            dispatchAction(getList({
                page,
                search
            }));

    }, [client.isRefetchList]);

    const searchList = () => {
        dispatchAction(getList({
            page: 1,
            search: search
        }));
    }

    const searchHandleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSearch(e.target.value);
    }

    const searchKeyPressHandle = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13) {
            searchList();
        }
    }

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        Payments
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="payments-search-container"
            >
                <FormControl
                    variant="outlined"
                    className="payments-search-input"
                    fullWidth
                >
                    <InputLabel>Search name</InputLabel>
                    <OutlinedInput
                        label="Search name"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={searchList}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={searchHandleChange}
                        value={search}
                        onKeyUp={searchKeyPressHandle}
                    />
                </FormControl>
            </Box>
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="payments-table"
            >
                {client.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>First Name</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Middle Name</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Last Name</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Contact #</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Address</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {client.list && client.list.length > 0
                                ?
                                client.list.map(item => {
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.first_name}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.middle_name}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.last_name}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.contact_number}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.address}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Stack direction="row" spacing={2}>
                                                    <Button onClick={() => navigate(`/payments/${item.id}`)} variant="contained" color="primary" startIcon={<VisibilityIcon />}>
                                                        View
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            {!client.isListLoading && client.listPagination && client.listPagination.total > 0 ?
                <Box
                    className="payments-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                               marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {client.listPagination.fromData} - {client.listPagination.toData} out of {client.listPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={client.listPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}
        </>
    )
}

export default Payments;