import { toast } from 'react-toastify';

export const showErrorToast = (message: string) => {
    toast.error(message || 'Something went wrong', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
    });
}