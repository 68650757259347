import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, Chip, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { getList, cancel } from '../../../../store/reducers/client-room';
import { IClientRoomListItem } from '../../../../types/client-room/list-item';
import dayjs from 'dayjs';
import toMoney from '../../../../utils/to-money';
import PaymentsIcon from '@mui/icons-material/Payments';
import getRole from '../../../../utils/get-role';
import { ICancel } from '../../../../types/room/cancel';
import toFixed from '../../../../utils/to-fixed';

interface IProps {
    client_id: number
}

const ClientRooms: React.FunctionComponent<IProps> = ({ client_id }) => {
    // Global
    const navigate = useNavigate();
    const clientRoom = useSelector((state: RootState) => state.clientRoom);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getList({
            page: value,
            client_id
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getList({
                page: 1,
                client_id
            }));
    }, []);

    useEffect(() => {
        if (clientRoom.isRefetchList)
            dispatchAction(getList({
                page,
                client_id
            }));

    }, [clientRoom.isRefetchList]);

    // Cancel
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const cancelModalHandleClose = () => setIsCancelModalOpen(false);
    const [cancelData, setCancelData] = useState<ICancel>({
        room_name: '',
        date: '',
        client_room_id: 0,
        cancelled_note: '',
        is_permanently_delete: false
    });

    const openCancelModal = (data: IClientRoomListItem) => {

        setCancelData({ ...data, is_permanently_delete: false });
        setIsCancelModalOpen(true);
    }

    const cancelClickEvent = () => {
        if (cancelData && cancelData.client_room_id)
            dispatchAction(cancel(cancelData));
    }

    useEffect(() => {
        if (!clientRoom.isCancelLoading)
            setIsCancelModalOpen(false);

    }, [clientRoom.isCancelLoading]);

    return (
        <>
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="client-rooms-table"
            >
                {clientRoom.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Number of Days Stayed</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Suite's Name</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Rates</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Additional Amount For Excess Guest</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Discount</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Excess Guest</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Total Amount</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Total Payed Amount</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Balance</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Status</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Notes of Cancelled</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clientRoom.list && clientRoom.list.length > 0
                                ?
                                clientRoom.list.map(item => {
                                    return (
                                        <TableRow key={item.client_room_id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.date !== item.date_end ? `${dayjs(item.date).format('MMMM D, YYYY')} - ${dayjs(item.date_end).format('MMMM D, YYYY')}` : dayjs(item.date).format('MMMM D, YYYY')}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.days}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.room_name}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.current_price)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.additional_amount_for_excess_guest)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.discount)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.additional_head}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.total_price)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.total_payed)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.balance)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{<Chip label={item.is_cancelled ? 'Cancelled' : 'Active'} color={item.is_cancelled ? 'error' : 'success'} />}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.cancelled_note ? item.cancelled_note : 'N/A'}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Button sx={{ marginRight: '10px' }} onClick={() => navigate(`/payments/${client_id}/room/${item.client_room_id}`)} variant="contained" color="primary" startIcon={<PaymentsIcon />}>
                                                    Payment
                                                </Button>
                                                <Button sx={{ display: getRole() === 1 ? 'default' : 'none' }} disabled={item.is_cancelled || Number(item.balance) === 0} onClick={() => openCancelModal(item)} variant="contained" color="error" startIcon={<HighlightOffIcon />}>
                                                    Cancel
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            {!clientRoom.isListLoading && clientRoom.listPagination && clientRoom.listPagination.total > 0 ?
                <Box
                    className="client-rooms-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                            marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {clientRoom.listPagination.fromData} - {clientRoom.listPagination.toData} out of {clientRoom.listPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={clientRoom.listPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}
            <Modal
                open={isCancelModalOpen}
                className="client-rooms-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="client-rooms-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Cancel
                    </Typography>

                    <Divider />

                    <Box
                        className="client-rooms-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to cancel client booking 'Room {cancelData.room_name} - {dayjs(cancelData.date).format('MMMM D, YYYY')}'?
                        </Typography>
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            label="Note"
                            variant="outlined"
                            fullWidth
                            multiline
                            maxRows={8}
                            minRows={8}
                            value={cancelData.cancelled_note}
                            onChange={(e) => {
                                setCancelData((previousVal: ICancel) => {
                                    return { ...previousVal, cancelled_note: e.target.value };
                                })
                            }}
                            disabled={clientRoom.isCancelLoading}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cancelData.is_permanently_delete}
                                    onChange={(e) => {
                                        setCancelData((previousVal: ICancel) => {
                                            return { ...previousVal, is_permanently_delete: e.target.checked };
                                        })
                                    }}
                                />
                            } label="Permanently Delete" />
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={cancelClickEvent}
                            disabled={clientRoom.isCancelLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: clientRoom.isCancelLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Yes
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={cancelModalHandleClose}
                            disabled={clientRoom.isCancelLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default ClientRooms;