import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showErrorToast } from "../../toast/error";
import { logout, refreshToken, setRefreshTokenExpire } from "./auth";
import { IAccountDetails } from "../../types/account-details";
import { IMyAccountChangePassword } from "../../types/my-account/create";
import { showSuccessToast } from "../../toast/success";

export const getMyAccountDetails = createAsyncThunk(
    'my-account/getAccountDetails',
    async (obj, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {
                    const { data } = await axios.get(
                        process.env.REACT_APP_API_URL!.toString() + '/api/auth/data',
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            };

            let result = await query();

            if (result.isTokenExpired === true) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {
                    
                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,
                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            localStorage.setItem('ACCOUNT_DETAILS', JSON.stringify(result.data));

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const changePassword = createAsyncThunk(
    'my-account/changePassword',
    async (queryParams:IMyAccountChangePassword, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {
                    const { data } = await axios.post(
                        process.env.REACT_APP_API_URL!.toString() + '/api/auth/change-password',
                        {
                            old_password: queryParams.old_password,
                            new_password: queryParams.new_password
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            };

            let result = await query();

            if (result.isTokenExpired === true) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {
                    
                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,
                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }
            else 
                showSuccessToast('Password successfully changed')

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
)

export const myAccountSlice = createSlice({
    name: 'my-account',
    initialState: {
        isRefreshTokenExpired: false,
        accountDetails: {} as IAccountDetails,

        isChangePasswordLoading: false,
        isChangePasswordSuccess: false
    },
    reducers: {

    },
    extraReducers: builder => {

        builder

            // get my account details
            .addCase(getMyAccountDetails.fulfilled, (state, action) => {
                if (action.payload.success && action.payload.data)
                    state.accountDetails = action.payload.data;

                state.isRefreshTokenExpired = action.payload.isRefreshTokenExpired;
            })

            // change password
            .addCase(changePassword.pending, state => {
                state.isChangePasswordLoading = false;
                state.isChangePasswordSuccess = false;
            })
            .addCase(changePassword.fulfilled, (state, { payload }) => {
                state.isChangePasswordSuccess = payload.success;
                state.isChangePasswordLoading = false;
            })
    }
});

export default myAccountSlice.reducer;