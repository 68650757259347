import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, Chip, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import dayjs, { Dayjs } from 'dayjs';
import { IRoomClientListItem } from '../../../../types/room-client/list-item';
import { IRoomClientCreate } from '../../../../types/room-client/create';
import { showWarningToast } from '../../../../toast/warn';
import { AppDispatch, RootState } from '../../../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { getList, create } from '../../../../store/reducers/room-client';
import { cancel } from '../../../../store/reducers/client-room';
import toMoney from '../../../../utils/to-money';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { IAutocomplete } from '../../../../types/autocomplete';
import { getAutocompleteList as getClientList, emptyAutocompleteList as emptyClientList } from '../../../../store/reducers/client';
import { getSingle } from '../../../../store/reducers/room';
import getRole from '../../../../utils/get-role';
import { ICancel } from '../../../../types/room/cancel';
import PaymentsIcon from '@mui/icons-material/Payments';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import isWholeNumber from '../../../../utils/is-whole-number';

interface IProps {
    room_id: number
}

const RoomClients: React.FunctionComponent<IProps> = ({ room_id }) => {
    // Global
    const navigate = useNavigate();
    const roomClient = useSelector((state: RootState) => state.roomClient);
    const clientRoom = useSelector((state: RootState) => state.clientRoom);
    const client = useSelector((state: RootState) => state.client);
    const dispatchAction = useDispatch<AppDispatch>();

    // List
    const [page, setPage] = useState(1);
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatchAction(getList({
            page: value,
            room_id
        }));
    };

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getList({
                page: 1,
                room_id
            }));
    }, []);

    useEffect(() => {
        if (roomClient.isRefetchList)
            dispatchAction(getList({
                page,
                room_id
            }));

    }, [roomClient.isRefetchList]);

    // Create
    const createDataPlaceholder = {
        room_id,
        client_id: 0,
        agent_user_id: 0
    }
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const createModalHandleClose = () => {
        setIsCreateModalOpen(false)
    }

    // Client
    const [createClientAutocompleteSelected, setCreateClientAutocompleteSelected] = useState<IAutocomplete>({
        value: 0,
        label: ''
    });
    const [createClientAutocompleteSearchId, setCreateClientAutocompleteSearchId] = useState<ReturnType<typeof setTimeout>>();
    const [createClientAutocompleteInput, setCreateClientAutocompleteInput] = useState('');
    const [createClientAutocompletePage, setCreateClientAutocompletePage] = useState(1);

    // Date
    const [bookDate, setBookDate] = useState<Dayjs | null>(null);
    const [additionalHead, setAdditionalHead] = useState(0);
    const [additionalAmountForExcessGuest, setAdditionalAmountForExcessGuest] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [days, setDays] = useState(1);

    const createClientAutocompleteNextPage = () => {
        if (createClientAutocompletePage + 1 <= client.autocompleteListPagination.totalPages) {
            dispatchAction(getClientList({
                page: createClientAutocompletePage + 1,
                search: createClientAutocompleteInput,
                addToCurrent: true
            }));

            setCreateClientAutocompletePage(previousVal => previousVal + 1);
        }
    }

    const onCreateClientAutocompleteInputChange = (search: string) => {

        setCreateClientAutocompleteSelected({
            value: 0,
            label: search
        });

        if (search === '') {
            setCreateClientAutocompletePage(1);
            dispatchAction(emptyClientList());
            return;
        }

        const searchingFound = client.autocompleteList.filter(item => item.label.toLocaleLowerCase().includes(search));

        if (searchingFound.length > 0)
            return;

        clearTimeout(createClientAutocompleteSearchId);

        setCreateClientAutocompleteSearchId(setTimeout(() => createClientAutocompleteSearch(search), 300))
    }

    const createClientAutocompleteSearch = (search: string) => {
        setCreateClientAutocompletePage(1);
        dispatchAction(getClientList({
            page: 1,
            search: search
        }));
    }

    const openCreateModal = () => {
        setCreateClientAutocompletePage(1);
        dispatchAction(emptyClientList());
        setIsCreateModalOpen(true);
    }

    const createClickEvent = () => {
        if (
            !(createClientAutocompleteSelected.value && createClientAutocompleteSelected.value > 0)
            || !bookDate
        ) {
            showWarningToast('Please fill up all fields');
            return;
        }

        if(days === 0)
        {
            showWarningToast('Day(s) must be 1 day and up');
            return;
        }

        dispatchAction(create({
            room_id,
            date: bookDate.format('YYYY-MM-D'),
            client_id: createClientAutocompleteSelected.value,
            additional_head: additionalHead,
            additional_amount_for_excess_guest: additionalAmountForExcessGuest,
            days: days,
            discount: discount
        }));
    }

    useEffect(() => {
        if (roomClient.isCreateSuccess)
            setIsCreateModalOpen(false);

    }, [roomClient.isCreateSuccess]);

    // Cancel
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const cancelModalHandleClose = () => setIsCancelModalOpen(false);
    const [cancelData, setCancelData] = useState<ICancel>({
        room_name: '',
        date: '',
        client_room_id: 0,
        cancelled_note: '',
        is_permanently_delete: false
    });

    const openCancelModal = (data: IRoomClientListItem) => {
        setCancelData({ ...data, is_permanently_delete: false });
        setIsCancelModalOpen(true);
    }

    const cancelClickEvent = () => {
        if (cancelData && cancelData.client_room_id)
            dispatchAction(cancel(cancelData));
    }

    useEffect(() => {
        if (!clientRoom.isCancelLoading)
            setIsCancelModalOpen(false);

    }, [clientRoom.isCancelLoading]);

    useEffect(() => {
        if (clientRoom.isRefetchList) {
            dispatchAction(getSingle(room_id));
            dispatchAction(getList({
                page: page,
                room_id
            }));
        }

    }, [clientRoom.isRefetchList]);

    return (
        <>
            {getRole() === 1 ?
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ShoppingCartIcon />}
                    onClick={openCreateModal}
                >
                    Book
                </Button>
                : null}
            <Modal
                open={isCreateModalOpen}
                className="room-clients-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="room-clients-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Book
                    </Typography>

                    <Divider />

                    <Box
                        className="room-clients-modal-form-controls"
                    >
                        <Autocomplete
                            noOptionsText="No result"
                            disablePortal
                            ListboxProps={{
                                role: 'list-box',
                                onScroll: (event: React.SyntheticEvent) => {
                                    const listboxNode = event.currentTarget;
                                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                                        createClientAutocompleteNextPage();
                                    }
                                }
                            }}
                            loading={client.isAutocompleteListLoading}
                            options={client.autocompleteList}
                            value={createClientAutocompleteSelected}
                            onChange={(event: any, newValue: IAutocomplete | null) => {
                                if (newValue)
                                    setCreateClientAutocompleteSelected(newValue)
                            }}
                            inputValue={createClientAutocompleteInput}
                            onInputChange={(event, newInputValue) => {
                                setCreateClientAutocompleteInput(newInputValue);
                                onCreateClientAutocompleteInputChange(newInputValue);
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} value={option.value} key={option.value}>
                                        {option.label}
                                    </li>
                                );
                            }}
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            renderInput={(params) => <TextField required {...params} label="Client" />}
                            disabled={roomClient.isCreateLoading}
                        />
                        <Box
                            className="daily-report-filter"
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                            >
                                <DatePicker
                                    label="Date"
                                    value={bookDate}
                                    onChange={(newValue) => {
                                        setBookDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField required {...params} fullWidth />}
                                    disabled={roomClient.isCreateLoading}
                                />
                            </LocalizationProvider>
                        </Box>
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Additional Head"
                            variant="outlined"
                            fullWidth
                            required
                            value={additionalHead}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setAdditionalHead(Number(e.target.value))
                                }
                            }}
                            disabled={roomClient.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Additional Amount For Excess Guest"
                            variant="outlined"
                            fullWidth
                            required
                            value={additionalAmountForExcessGuest}
                            onChange={(e) => {
                                    setAdditionalAmountForExcessGuest(Number(e.target.value))
                            }}
                            disabled={roomClient.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Discount"
                            variant="outlined"
                            fullWidth
                            required
                            value={discount}
                            onChange={(e) => {
                                    setDiscount(Number(e.target.value))
                            }}
                            disabled={roomClient.isCreateLoading}
                        />
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px'
                            }}
                            label="Days"
                            variant="outlined"
                            fullWidth
                            required
                            value={days}
                            onChange={(e) => {
                                if (isWholeNumber(e.target.value)) {
                                    setDays(Number(e.target.value))
                                }
                            }}
                            disabled={roomClient.isCreateLoading}
                        />
                        <Button
                            sx={{
                                marginBottom: '10px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            onClick={createClickEvent}
                            disabled={roomClient.isCreateLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: roomClient.isCreateLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Create
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={createModalHandleClose}
                            disabled={roomClient.isCreateLoading}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <TableContainer
                sx={{
                    maxHeight: '500px'
                }}
                component={Paper}
                className="room-clients-table"
            >
                {roomClient.isListLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '30px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Client</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Date</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Number of Days Stayed</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Suite's Name</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Rates</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Additional Amount For Excess Guest</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Discount</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Excess Guest</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Total Amount</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Total Payed Amount</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Balance</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Status</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Notes of Cancelled</TableCell>
                                <TableCell sx={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {roomClient.list && roomClient.list.length > 0
                                ?
                                roomClient.list.map(item => {
                                    return (
                                        <TableRow key={item.client_room_id}>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Link
                                                    underline="hover"
                                                    sx={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigate(`/clients/${item.client_id}`)}
                                                >
                                                    {item.client_first_name} {item.client_middle_name} {item.client_last_name}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.date !== item.date_end ? `${dayjs(item.date).format('MMMM D, YYYY')} - ${dayjs(item.date_end).format('MMMM D, YYYY')}` : dayjs(item.date).format('MMMM D, YYYY')}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.days}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.room_name}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.current_price)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.additional_amount_for_excess_guest)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.discount)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.additional_head}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.total_price)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.total_payed)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{toMoney(item.balance)}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{<Chip label={item.is_cancelled ? 'Cancelled' : 'Active'} color={item.is_cancelled ? 'error' : 'success'} />}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{item.cancelled_note ? item.cancelled_note : 'N/A'}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                <Button sx={{ marginRight: '10px' }} onClick={() => navigate(`/payments/${item.client_id}/room/${item.client_room_id}`)} variant="contained" color="primary" startIcon={<PaymentsIcon />}>
                                                    Payment
                                                </Button>
                                                <Button sx={{ display: getRole() === 1 ? 'default' : 'none' }} disabled={item.is_cancelled || Number(item.balance) === 0} onClick={() => openCancelModal(item)} variant="contained" color="error" startIcon={<HighlightOffIcon />}>
                                                    Cancel
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }} className="table-cell">
                                        No Data
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                }
            </TableContainer>
            {!roomClient.isListLoading && roomClient.listPagination && roomClient.listPagination.total > 0 ?
                <Box
                    className="room-clients-pagination-container"
                >
                    <Typography
                        fontSize="small"
                        sx={{
                            marginBottom: '10px',
                            marginTop: '10px',
                            display: 'block'
                        }}
                    >
                        {roomClient.listPagination.fromData} - {roomClient.listPagination.toData} out of {roomClient.listPagination.overallTotal}
                    </Typography>
                    <Pagination
                        count={roomClient.listPagination.totalPages}
                        shape="rounded"
                        page={page}
                        onChange={paginationHandleChange}
                    />
                </Box>
                : null}
            <Modal
                open={isCancelModalOpen}
                className="room-clients-modal"
                keepMounted
            >
                <Box
                    component="form"
                    className="room-clients-modal-form"
                >
                    <Typography
                        variant="h6"
                        sx={{
                            padding: '15px',
                            textAlign: 'center'
                        }}
                    >
                        Cancel
                    </Typography>

                    <Divider />

                    <Box
                        className="room-clients-modal-form-controls"
                    >
                        <Typography
                            textAlign="center"
                        >
                            Do you want to cancel client booking 'Room {cancelData.room_name} - {dayjs(cancelData.date).format('MMMM D, YYYY')}'?
                        </Typography>
                        <TextField
                            sx={{
                                display: 'block',
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            label="Note"
                            variant="outlined"
                            fullWidth
                            multiline
                            maxRows={8}
                            minRows={8}
                            value={cancelData.cancelled_note}
                            onChange={(e) => {
                                setCancelData((previousVal: ICancel) => {
                                    return { ...previousVal, cancelled_note: e.target.value };
                                })
                            }}
                            disabled={clientRoom.isCancelLoading}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cancelData.is_permanently_delete}
                                    onChange={(e) => {
                                        setCancelData((previousVal: ICancel) => {
                                            return { ...previousVal, is_permanently_delete: e.target.checked };
                                        })
                                    }}
                                />
                            } label="Permanently Delete" />
                        <Button
                            sx={{
                                marginBottom: '10px',
                                marginTop: '20px'
                            }}
                            variant="contained"
                            size="large"
                            fullWidth
                            color="error"
                            onClick={cancelClickEvent}
                            disabled={clientRoom.isCancelLoading}
                        >
                            <CircularProgress
                                size="1em"
                                sx={{
                                    display: clientRoom.isCancelLoading ? 'inherit' : 'none',
                                    marginRight: '10px'
                                }}
                            />
                            Yes
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            onClick={cancelModalHandleClose}
                            disabled={clientRoom.isCancelLoading}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default RoomClients;