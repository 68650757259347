import { Box } from '@mui/system';
import './styles.css';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { logout } from '../../../store/reducers/auth';
import { getMyAccountDetails } from '../../../store/reducers/my-account';

const Header: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const myAccount = useSelector((state: RootState) => state.myAccount);
    const auth = useSelector((state: RootState) => state.auth);
    const dispatchAction = useDispatch<AppDispatch>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const goToMyAccount = () => {
        handleClose();
        navigate('/my-account');
    }

    useEffect(() => {
        if (!localStorage.getItem('ACCESS_TOKEN'))
            navigate('/')
    });

    useEffect(() => {
        if (localStorage.getItem('ACCESS_TOKEN'))
            dispatchAction(getMyAccountDetails());
    }, []); 

    useEffect(()=> {
        if(auth.isRefreshTokenExpired)
            navigate('/')
    }, [auth.isRefreshTokenExpired])

    return (
        <>
            <Box
                className="header"
            >
                <Box
                    className="header-container"
                >
                    {myAccount.accountDetails ?
                        <Typography className="header-account-name">
                            <b>{myAccount.accountDetails.first_name} {myAccount.accountDetails.middle_name} {myAccount.accountDetails.last_name}</b>, {myAccount.accountDetails.role_name}
                        </Typography>
                        : null}
                    <IconButton
                        id="header-account-menu"
                        onClick={handleClick}
                    >
                        <AccountCircleIcon color="primary" />
                    </IconButton>
                </Box>
            </Box>
            <Menu
                id="header-account-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'header-account-menu"',
                }}
            >
                <MenuItem onClick={goToMyAccount}>
                    <SettingsIcon
                        className="header-account-menu-icon"
                    />
                    Account Settings
                </MenuItem>
                <MenuItem onClick={() => {
                    dispatchAction(logout());
                    navigate('/');
                }}>
                    <LogoutIcon
                        className="header-account-menu-icon"
                    />
                    Logout
                </MenuItem>
            </Menu>
        </>
    )
}

export default Header;