import { Typography, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, Button, Pagination, Link, Modal, TextField, Icon, Divider, Autocomplete, Breadcrumbs, Chip, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { AppDispatch, RootState } from '../../../../store/store';
import { AsyncThunkAction, Dispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { IAutocomplete } from '../../../../types/autocomplete';
import { emptyAutocompleteList as emptyPaymentOptionAutocompleteList, getAutocompleteList as getPaymentOptionAutocompleteList } from '../../../../store/reducers/payment-option';
import { showWarningToast } from '../../../../toast/warn';
import { getList } from '../../../../store/reducers/daily-report';
import toMoney from '../../../../utils/to-money';
import toFixed from '../../../../utils/to-fixed';

const DailyReport: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatchAction = useDispatch<AppDispatch>();
    const [page, setPage] = useState(1);
    const dailyReport = useSelector((state: RootState) => state.dailyReport);
    const paginationHandleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const [fromDateValue, setFromDateValue] = useState<Dayjs | null>(null);
    const [toDateValue, setToDateValue] = useState<Dayjs | null>(null);

    const showReport = () => {

        if (!fromDateValue || !toDateValue) {
            showWarningToast('Please select "Date from" and "Date to"');
            return;
        }

        if (fromDateValue.isAfter(toDateValue)) {
            showWarningToast('"Date to" is earlier than "Date from"');
            return;
        }

        dispatchAction(getList({
            from: fromDateValue.format('YYYY-MM-D'),
            to: toDateValue.format('YYYY-MM-D')
        }));
    }

    return (
        <>
            <Box
                className="dashboard-header"
            >
                <Breadcrumbs>
                    <Link
                        underline="none"
                        color="text.primary"
                        aria-current="page"
                    >
                        Daily Report
                    </Link>
                </Breadcrumbs>
            </Box>
            <Box
                className="daily-report-filter-container"
            >
                <Box
                    className="daily-report-filter"
                >
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                    >
                        <DatePicker
                            label="From"
                            value={fromDateValue}
                            onChange={(newValue) => {
                                setFromDateValue(newValue);
                            }}
                            renderInput={(params) => <TextField required {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box
                    className="daily-report-filter"
                >
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                    >
                        <DatePicker
                            label="To"
                            value={toDateValue}
                            onChange={(newValue) => {
                                setToDateValue(newValue);
                            }}
                            renderInput={(params) => <TextField required {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                </Box>
                <Button
                    variant="contained"
                    color="success"
                    startIcon={<LeaderboardIcon />}
                    sx={{
                        marginBottom: '20px'
                    }}
                    onClick={showReport}
                >
                    Show Report
                </Button>
            </Box>
            <Divider
                sx={{
                    marginBottom: '20px',
                }}
            />
            <Box
                className="daily-report-info-container"
                sx={{
                    marginTop: '20px'
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: dailyReport.isListLoading ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <CircularProgress />
                </Box>
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: !dailyReport.data.total_sales ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    height: '100%',
                    width: '100%',
                    zIndex: 3,
                    borderRadius: '10px'
                }}>
                    <Typography>
                        Report will be shown here
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Cancelled Sales
                    </Typography>
                    <Typography>
                        {toMoney(dailyReport.data.total_cancelled_sales)}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                        color="primary"
                    >
                        Total Downpayment
                    </Typography>
                    <Typography>
                        {toMoney(dailyReport.data.total_sales)}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                    >
                        Total Collectibles
                    </Typography>
                    <Typography>
                        {toMoney(dailyReport.data.total_collectibles)}
                    </Typography>
                </Box>
                <Box
                    className="daily-report-info"
                >
                    <Typography
                        sx={{
                            fontWeight: '700',
                            marginRight: '5px'
                        }}
                        color="primary"
                    >
                        Total Sales
                    </Typography>
                    <Typography>
                        {toMoney(dailyReport.data.total_room_sales)}
                    </Typography>
                </Box>
            </Box>
            
        </>
    )
}

export default DailyReport;
