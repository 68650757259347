import { Box } from "@mui/system";
import './styles.css';

interface IProps {
    children: React.ReactNode
}

const View: React.FunctionComponent<IProps> = (props) => {
    return (
        <>
            <Box
                className="view"
            >
                {props.children}
            </Box>
        </>
    )
}

export default View;