import { Box } from '@mui/system';
import './styles.css';
import SideBar from '../../components/commons/side-bar';
import Header from '../../components/commons/header';
import HorizontalDivs from '../../components/commons/horizontal-divs';
import View from '../../components/commons/view';
import PaymentOptions from '../../components/page-components/dashboard/payment-options';
import Clients from '../../components/page-components/dashboard/clients';
import SingleRoom from '../../components/page-components/dashboard/single-room';
import SingleClient from '../../components/page-components/dashboard/single-client';
import SinglePayment from '../../components/page-components/dashboard/single-payment';
import SinglePaymentClientRoom from '../../components/page-components/dashboard/single-payment-client-room';
import Payments from '../../components/page-components/dashboard/payments';
import DailyReport from '../../components/page-components/dashboard/daily-report';
import MyAccount from '../../components/page-components/dashboard/my-account';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import Rooms from '../../components/page-components/dashboard/rooms';
import Calendar from '../../components/page-components/dashboard/calendar';

interface IProps {
    page: string
}

const Dashboard: React.FunctionComponent<IProps> = (props) => {

    const myAccount = useSelector((state: RootState) => state.myAccount);

    return (
        <>
            <Box
                className="dashboard-container"
            >
                <Header />
                {myAccount.accountDetails.username === 'admin' ?
                    <HorizontalDivs>
                        <SideBar page={props.page} />
                        <View>
                            {props.page === 'payment-options' ? <PaymentOptions /> : null}
                            {props.page === 'clients' ? <Clients /> : null}
                            {props.page === 'rooms' ? <Rooms /> : null}
                            {props.page === 'single-room' ? <SingleRoom /> : null}
                            {props.page === 'single-client' ? <SingleClient /> : null}
                            {props.page === 'payments' ? <Payments /> : null}
                            {props.page === 'single-payment' ? <SinglePayment /> : null}
                            {props.page === 'single-payment-client-room' ? <SinglePaymentClientRoom /> : null}
                            {props.page === 'reports' ? <DailyReport /> : null}
                            {props.page === 'my-account' ? <MyAccount /> : null}
                            {props.page === 'calendar' ? <Calendar /> : null}
                        </View>
                    </HorizontalDivs>
                    : <Calendar />}
            </Box>
        </>
    )
}

export default Dashboard