import React, { useEffect, useState } from 'react';
import './styles.css';
import { Box } from '@mui/system';
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse, Typography, Button, Badge } from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';
import PaymentsIcon from '@mui/icons-material/Payments';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import getRole from '../../../utils/get-role';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface IProps {
    page: string
}

const SideBar: React.FunctionComponent<IProps> = (props) => {

    const navigate = useNavigate();
    const myAccount = useSelector((state: RootState) => state.myAccount);

    return (
        <>
            {myAccount.accountDetails.username === 'admin' ?
            <Box
                className="side-bar-container"
            >
                <List
                    sx={{ padding: 0, overflowX: 'hidden' }}
                >
                    <ListItemButton
                        className="side-bar-menu"
                        selected={props.page === 'calendar'}
                        onClick={() => navigate('/calendar')}
                    >
                        <ListItemIcon sx={{
                            minWidth: '30px'
                        }}>
                            <CalendarMonthIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText  className="side-bar-text" primary="Calendar" />
                    </ListItemButton>
                    <ListItemButton
                        className="side-bar-menu"
                        selected={props.page === 'rooms'}
                        onClick={() => navigate('/rooms')}
                    >
                        <ListItemIcon sx={{
                            minWidth: '30px'
                        }}>
                            <HouseSidingIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText  className="side-bar-text" primary="Rooms" />
                    </ListItemButton>
                    <ListItemButton
                        className="side-bar-menu"
                        selected={props.page === 'payment-options'}
                        onClick={() => navigate('/payment-options')}
                    >
                        <ListItemIcon sx={{
                            minWidth: '30px'
                        }}>
                            <PaymentsIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText  className="side-bar-text" primary="Payment Options" />
                    </ListItemButton>
                    <ListItemButton
                        className="side-bar-menu"
                        selected={props.page === 'clients'}
                        onClick={() => navigate('/clients')}
                    >
                        <ListItemIcon sx={{
                            minWidth: '30px'
                        }}>
                            <PermContactCalendarIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText  className="side-bar-text" primary="Clients" />
                    </ListItemButton>
                    <ListItemButton
                        className="side-bar-menu hidden-side-bar-menu"
                        selected={props.page === 'reports'}
                        onClick={() => navigate('/reports')}
                    >
                        <ListItemIcon sx={{
                            minWidth: '30px'
                        }}>
                            <TodayIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText  className="side-bar-text" primary="Reports" />
                    </ListItemButton>
                    <ListItemButton
                        className="side-bar-menu"
                        selected={props.page === 'payments'}
                        onClick={() => navigate('/payments')}
                    >
                        <ListItemIcon sx={{
                            minWidth: '30px'
                        }}>
                            <PointOfSaleIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText  className="side-bar-text" primary="Payments" />
                    </ListItemButton>
                </List>
            </Box>
            : null }
        </>
    )
}

export default SideBar