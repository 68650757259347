import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IRoomListItem } from '../../types/room/list-item';
import { IPagination } from '../../types/pagination';
import axios from 'axios';
import { logout, refreshToken, setRefreshTokenExpire } from './auth';
import { showErrorToast } from '../../toast/error';
import { showSuccessToast } from '../../toast/success';
import { IRoomCreate } from '../../types/room/create';
import { IRoomEdit } from '../../types/room/edit';
import { IRoom } from '../../types/room/single';
import toFixed from '../../utils/to-fixed';


const getListQuery = async () => {
    try {


        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + `/api/room/`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                }
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const getList = createAsyncThunk(
    'room/list',
    async (queryParams: null, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {


            let result = await getListQuery();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await getListQuery();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const create = createAsyncThunk(
    'room/create',
    async (queryParams: IRoomCreate, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {

                    const { data } = await axios.post(
                        process.env.REACT_APP_API_URL!.toString() + `/api/room`,
                        {
                            name: queryParams.name,
                            price: Number(queryParams.price)
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            }

            let result = await query();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }
            else {
                showSuccessToast('Successfully created');
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

const getSingleQuery = async (id: number) => {
    try {

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + `/api/room/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                }
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const update = createAsyncThunk(
    'room/update',
    async (queryParams: IRoomEdit, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {

                    const { data } = await axios.patch(
                        process.env.REACT_APP_API_URL!.toString() + `/api/room/${queryParams.id}`,
                        {
                            name: queryParams.name,
                            price: Number(queryParams.price)
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            }

            let result = await query();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }
            else {
                showSuccessToast('Successfully updated');
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const _delete = createAsyncThunk(
    'room/delete',
    async (id: number, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {

                    const { data } = await axios.delete(
                        process.env.REACT_APP_API_URL!.toString() + `/api/room/${id}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            }

            let result = await query();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }
            else {
                showSuccessToast('Successfully deleted');
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const getSingleForEdit = createAsyncThunk(
    'room/getSingleForEdit',
    async (id: number, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {

            let result = await getSingleQuery(id);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,
                    });
                }
                else
                    result = await getSingleQuery(id);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const getSingle = createAsyncThunk(
    'room/getSingle',
    async (id: number, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {

            let result = await getSingleQuery(id);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,
                    });
                }
                else
                    result = await getSingleQuery(id);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const roomSlice = createSlice({
    name: 'room',
    initialState: {

        // List
        list: [] as IRoomListItem[],
        listPagination: {} as IPagination,
        isListLoading: false,
        isRefetchList: false,

        // Create
        isCreateLoading: false,
        isCreateSuccess: false,

        // Edit
        isEditGetSingleLoading: false,
        isEditGetSingleSuccess: false,
        editSingleData: {} as IRoomEdit,
        isUpdateLoading: false,
        isUpdateSuccess: false,

        // Delete
        isDeleteLoading: false,
        isDeleteSuccess: false,

        // Single
        isGetSingleLoading: false,
        isGetSingleSuccess: false,
        singleData: {} as IRoom
    },
    reducers: {
    },
    extraReducers: builder => {

        builder

            // list
            .addCase(getList.pending, state => {
                state.isListLoading = true;
            })
            .addCase(getList.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data) {
                    state.list = payload.data;
                    state.listPagination = payload;
                }

                state.isListLoading = false;
                state.isRefetchList = false;
                state.isDeleteSuccess = false;
            })

            // create
            .addCase(create.pending, state => {
                state.isRefetchList = false;
                state.isCreateSuccess = false;
                state.isCreateLoading = true;
            })
            .addCase(create.fulfilled, (state, { payload }) => {
                state.isCreateSuccess = payload.success;
                state.isRefetchList = payload.success;
                state.isCreateLoading = false;
            })

            // edit get single
            .addCase(getSingleForEdit.pending, state => {
                state.isEditGetSingleSuccess = false;
                state.isEditGetSingleLoading = true;
            })
            .addCase(getSingleForEdit.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data)
                {
                    state.editSingleData = payload.data;
                }

                state.isEditGetSingleSuccess = payload.success;
                state.isEditGetSingleLoading = false;
            })

            // update
            .addCase(update.pending, state => {
                state.isRefetchList = false;
                state.isUpdateSuccess = false;
                state.isUpdateLoading = true;
            })
            .addCase(update.fulfilled, (state, { payload }) => {
                state.isUpdateSuccess = payload.success;
                state.isRefetchList = payload.success;
                state.isUpdateLoading = false;
            })

            // delete
            .addCase(_delete.pending, state => {
                state.isRefetchList = false;
                state.isDeleteSuccess = false;
                state.isDeleteLoading = true;
            })
            .addCase(_delete.fulfilled, (state, { payload }) => {
                state.isDeleteSuccess = payload.success;
                state.isRefetchList = payload.success;
                state.isDeleteLoading = false;
            })

            // get single
            .addCase(getSingle.pending, state => {
                state.isGetSingleSuccess = false;
                state.isGetSingleLoading = true;
            })
            .addCase(getSingle.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data)
                    state.singleData = payload.data;

                state.isGetSingleSuccess = payload.success;
                state.isGetSingleLoading = false;
            })
    }
});

export default roomSlice.reducer;