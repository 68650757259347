import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IPagination } from '../../types/pagination';
import axios from 'axios';
import { logout, refreshToken, setRefreshTokenExpire } from './auth';
import { showErrorToast } from '../../toast/error';
import { showSuccessToast } from '../../toast/success';
import { IClientRoomListItem } from '../../types/client-room/list-item';
import { IClientRoom } from '../../types/client-room/single';
import { IClientRoomPaymentListItem } from '../../types/client-room/payment-list-item';
import { IClientRoomPay } from '../../types/client-room/pay';
import { ICancel } from '../../types/room/cancel';
import { IClientRoomBooked } from '../../types/client-room/list-booked';

interface IListParams {
    page: number,
    client_id: number
}

const getListQuery = async (queryParams: IListParams) => {
    try {
        const paramsData = [
            ['page', queryParams.page.toString()]
        ];

        const params = new URLSearchParams(paramsData);

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + `/api/client/${queryParams.client_id}/room`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                },
                params
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const getList = createAsyncThunk(
    'client-room/list',
    async (queryParams: IListParams, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {


            let result = await getListQuery(queryParams);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await getListQuery(queryParams);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

interface IPaymentListParams {
    client_room_id: number,
    page: number
}

const getPaymentListQuery = async (queryParams: IPaymentListParams) => {
    try {
        const paramsData = [
            ['page', queryParams.page.toString()]
        ];

        const params = new URLSearchParams(paramsData);

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + `/api/client-room/${queryParams.client_room_id}/payment`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                },
                params
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const getPayments = createAsyncThunk(
    'client-room/getPayments',
    async (queryParams: IPaymentListParams, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {


            let result = await getPaymentListQuery(queryParams);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await getPaymentListQuery(queryParams);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const cancel = createAsyncThunk(
    'client-room/cancel',
    async (queryParams: ICancel, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {

                    const { data } = await axios.post(
                        process.env.REACT_APP_API_URL!.toString() + `/api/room/${queryParams.client_room_id}/cancel`,
                        {
                            cancelled_note: queryParams.cancelled_note,
                            is_permanently_delete: queryParams.is_permanently_delete
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            }

            let result = await query();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }
            else {
                showSuccessToast('Successfully canceld');
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const pay = createAsyncThunk(
    'client-room/pay',
    async (queryParams: IClientRoomPay, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {

                    const { data } = await axios.post(
                        process.env.REACT_APP_API_URL!.toString() + `/api/client-room/${queryParams.client_room_id}/pay`,
                        {
                            payment_type_id: queryParams.payment_type_id,
                            amount: queryParams.amount,
                            cash: queryParams.cash,
                            payment_note: queryParams.payment_note
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            }

            let result = await query();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }
            else {
                showSuccessToast('Payment success');
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

const getSingleQuery = async (id: number) => {
    try {

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + `/api/client-room/${id}/status`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                }
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const getSingle = createAsyncThunk(
    'client-room/getSingle',
    async (id: number, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            let result = await getSingleQuery(id);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,
                    });
                }
                else
                    result = await getSingleQuery(id);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

interface IListBookedParams {
    month: string,
    year: string
}

export const getListBooked = createAsyncThunk(
    'client-room/room-booking',
    async (queryParams: IListBookedParams, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {

                    const paramsData = [
                        ['month', queryParams.month],
                        ['year', queryParams.year]
                    ];
            
                    const params = new URLSearchParams(paramsData);

                    const { data } = await axios.get(
                        process.env.REACT_APP_API_URL!.toString() + `/api/room-booking/`,
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            },
                            params
                        },
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            }

            let result = await query();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const clientRoom = createSlice({
    name: 'client-room',
    initialState: {

        // List
        list: [] as IClientRoomListItem[],
        listPagination: {} as IPagination,
        isListLoading: false,
        isRefetchList: false,

        // List
        listBooked: [] as IClientRoomBooked[],
        isListBookedLoading: false,

        // Payment List
        paymentList: [] as IClientRoomPaymentListItem[],
        paymentListPagination: {} as IPagination,
        isPaymentListLoading: false,
        isRefetchPaymentList: false,

        // cancel
        isCancelLoading: false,
        isCancelSuccess: false,

        // Single
        isGetSingleLoading: false,
        isGetSingleSuccess: false,
        singleData: {} as IClientRoom,

        // pay
        isPayLoading: false,
        isPaySuccess: false,
        isRefetchGetSingle: false
    },
    reducers: {
    },
    extraReducers: builder => {

        builder

            // list
            .addCase(getList.pending, state => {
                state.isListLoading = true;
            })
            .addCase(getList.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data) {
                    state.list = payload.data;
                    state.listPagination = payload;
                }

                state.isListLoading = false;
                state.isRefetchList = false;
            })

            // list booked
            .addCase(getListBooked.pending, state => {
                state.isListBookedLoading = true;
            })
            .addCase(getListBooked.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data) {
                    state.listBooked = payload.data;
                }

                state.isListBookedLoading = false;
            })

            // payment list
            .addCase(getPayments.pending, state => {
                state.isPaymentListLoading = true;
            })
            .addCase(getPayments.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data) {
                    state.paymentList = payload.data;
                    state.paymentListPagination = payload;
                }

                state.isPaymentListLoading = false;
                state.isRefetchPaymentList = false;
            })

            // cancel
            .addCase(cancel.pending, state => {
                state.isRefetchList = false;
                state.isCancelSuccess = false;
                state.isCancelLoading = true;
            })
            .addCase(cancel.fulfilled, (state, { payload }) => {
                state.isCancelSuccess = payload.success;
                state.isRefetchList = payload.success;
                state.isCancelLoading = false;
            })

            // get single
            .addCase(getSingle.pending, state => {
                state.isGetSingleSuccess = false;
                state.isGetSingleLoading = true;
            })
            .addCase(getSingle.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data)
                    state.singleData = payload.data;

                state.isGetSingleSuccess = payload.success;
                state.isGetSingleLoading = false;
            })

            // pay
            .addCase(pay.pending, state => {
                state.isRefetchGetSingle = false;
                state.isRefetchPaymentList = false;
                state.isPaySuccess = false;
                state.isPayLoading = true;
            })
            .addCase(pay.fulfilled, (state, { payload }) => {
                state.isPaySuccess = payload.success;
                state.isRefetchPaymentList = payload.success;
                state.isPayLoading = false;
                state.isRefetchGetSingle = payload.success;

                if (payload.success) {
                    localStorage.setItem('print', JSON.stringify(payload.data));
                    window.open(`/receipt/`);
                }
            })
    }
});

export default clientRoom.reducer;