import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IRoomClientListItem } from '../../types/room-client/list-item';
import { IPagination } from '../../types/pagination';
import axios from 'axios';
import { logout, refreshToken, setRefreshTokenExpire } from './auth';
import { showErrorToast } from '../../toast/error';
import { showSuccessToast } from '../../toast/success';
import { IRoomClientCreate } from '../../types/room-client/create';
import { ICancel } from '../../types/room/cancel';

interface IListParams {
    room_id: number,
    page: number
}

const getListQuery = async (queryParams: IListParams) => {
    try {
        const paramsData = [
            ['page', queryParams.page.toString()]
        ];

        const params = new URLSearchParams(paramsData);

        const { data } = await axios.get(
            process.env.REACT_APP_API_URL!.toString() + `/api/room/${queryParams.room_id}/client`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                },
                params
            }
        );

        return data;
    }
    catch (err) {
        return {
            success: false
        }
    }
}

export const getList = createAsyncThunk(
    'room-client/list',
    async (queryParams: IListParams, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {


            let result = await getListQuery(queryParams);

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await getListQuery(queryParams);
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const create = createAsyncThunk(
    'room-client/create',
    async (queryParams: IRoomClientCreate, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const query = async () => {
                try {

                    const { data } = await axios.post(
                        process.env.REACT_APP_API_URL!.toString() + `/api/room/${queryParams.room_id}/book`,
                        {
                            additional_head: queryParams.additional_head,
                            date: queryParams.date,
                            client_id: queryParams.client_id,
                            days: queryParams.days,
                            additional_amount_for_excess_guest: queryParams.additional_amount_for_excess_guest,
                            discount: queryParams.discount
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
                            }
                        }
                    );

                    return data;
                }
                catch (err) {
                    return {
                        success: false
                    }
                }
            }

            let result = await query();

            if (result.isTokenExpired) {
                const refreshTokenResult = await refreshToken();

                if (!refreshTokenResult.success) {

                    logout();
                    dispatch(setRefreshTokenExpire());
                    return fulfillWithValue({
                        success: false,

                    });
                }
                else
                    result = await query();
            }

            if (!result.success) {
                showErrorToast(result.message);
                return fulfillWithValue({
                    success: false
                });
            }
            else {
                showSuccessToast('Successfully booked');
            }

            return fulfillWithValue(result);
        }
        catch (err) {
            return fulfillWithValue({
                success: false
            });
        }
    }
);

export const roomClient = createSlice({
    name: 'room-client',
    initialState: {

        // List
        list: [] as IRoomClientListItem[],
        listPagination: {} as IPagination,
        isListLoading: false,
        isRefetchList: false,

        // Create
        isCreateLoading: false,
        isCreateSuccess: false
    },
    reducers: {
    },
    extraReducers: builder => {

        builder

            // list
            .addCase(getList.pending, state => {
                state.isListLoading = true;
            })
            .addCase(getList.fulfilled, (state, { payload }) => {

                if (payload.success && payload.data) {
                    state.list = payload.data;
                    state.listPagination = payload;
                }

                state.isListLoading = false;
                state.isRefetchList = false;
            })

            // create
            .addCase(create.pending, state => {
                state.isRefetchList = false;
                state.isCreateSuccess = false;
                state.isCreateLoading = true;
            })
            .addCase(create.fulfilled, (state, { payload }) => {
                state.isCreateSuccess = payload.success;
                state.isRefetchList = payload.success;
                state.isCreateLoading = false;
            })
    }
});

export default roomClient.reducer;