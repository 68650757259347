import { Box } from "@mui/system";
import './styles.css';

interface IProps {
    children: React.ReactNode
}

const HorizontalDivs: React.FunctionComponent<IProps> = (props) => {
    return (
        <>
            <Box
                className="horizontal-divs"
            >
                {props.children}
            </Box>
        </>
    )
}

export default HorizontalDivs;